import { useContext } from 'react';

import { useAppSelector, useAppDispatch } from 'hooks';

import { Text, Box, Flex, Button } from '@chakra-ui/react';

import { DeleteIcon } from '@chakra-ui/icons';

import Card from 'components/card/Card';

import FormInput from '../../components/FormInput';
import Divider from '../../components/Divider';

import {
  changeInsuranceCoverageLiability,
  changeInsuranceCovergaeAccidentBenefits,
  changeInsuranceCoverageUninsuredAutomobile,
  changeInsuranceCoverageDirectCompensation,
  changeInsuranceCoverageLossOrDamage,
  changePolicyChangeFormsFamilyProtectionCoverage,
  changePolicyChangeFormsOtherCoverages,
  addPolicyChangeFormsOtherCoverages,
  removePolicyChangeFormsOtherCoverages,
} from 'reducers/submissionFormReducer';

import isSubmissionDisabled from 'views/submission/helpers/isSubmissionDisabled';

import { AuthContext } from 'contexts/AuthContext';

import { SECTIONS } from 'views/submission/constants';

const InsuranceCoverages = () => {
  const { organization } = useContext(AuthContext);
  const dispatch = useAppDispatch();

  const insurance_coverages = useAppSelector(
    (state) => state.submissionForm.data.insurance_coverages,
  );

  const submissionStatus = useAppSelector(
    (state) => state.submissionForm.status,
  );

  const isDisabled = isSubmissionDisabled(submissionStatus, organization.role);

  const onInsuranceCoverageLiabilityChange = (
    event: any,
    index: number,
    type: string,
  ) => {
    const { name, value } = event.target;
    const limit = type === 'limit' ? value : undefined;
    const premium = type === 'premium' ? value : undefined;

    dispatch(changeInsuranceCoverageLiability({ index, name, limit, premium }));
  };

  const onInsuranceCovergaeAccidentBenefitsChange = (
    event: any,
    index: number,
  ) => {
    dispatch(
      changeInsuranceCovergaeAccidentBenefits({
        index,
        value: event.target.value,
      }),
    );
  };

  const onInsuranceCoverageUninsuredAutomobileChange = (
    event: any,
    index: number,
  ) => {
    dispatch(
      changeInsuranceCoverageUninsuredAutomobile({
        index,
        value: event.target.value,
      }),
    );
  };

  const onInsuranceCoverageDirectCompensationChange = (
    event: any,
    index: number,
    type: string,
  ) => {
    const { value } = event.target;
    const deductible = type === 'deductible' ? value : undefined;
    const premium = type === 'premium' ? value : undefined;

    dispatch(
      changeInsuranceCoverageDirectCompensation({ index, deductible, premium }),
    );
  };

  const onInsuranceCoverageLossOrDamageChange = (
    event: any,
    index: number,
    type: string,
  ) => {
    const { value, name } = event.target;
    const deductible = type === 'deductible' ? value : undefined;
    const premium = type === 'premium' ? value : undefined;

    dispatch(
      changeInsuranceCoverageLossOrDamage({ index, name, deductible, premium }),
    );
  };

  const onPolicyChangeFormsFamilyProtectionCoverageChange = (
    event: any,
    index: number,
  ) => {
    dispatch(
      changePolicyChangeFormsFamilyProtectionCoverage({
        index,
        value: event.target.value,
      }),
    );
  };

  const onPolicyChangeFormsOtherCoveragesChange = (
    event: any,
    index: number,
    subIndex: number,
  ) => {
    const { name, value } = event.target;

    dispatch(
      changePolicyChangeFormsOtherCoverages({
        index,
        subIndex,
        name,
        value,
      }),
    );
  };

  return (
    <Card mb="20px" id="sectionInsuranceCoverages">
      <Text fontWeight="bold" fontSize="2xl">
        Insurance Coverages Applied For
      </Text>

      {insurance_coverages.map((insurance_coverage, index) => {
        return (
          <Box key={index} mt="20px">
            <Divider mb="20px">Automobile {index + 1}</Divider>

            <Flex gap="20px">
              <Box w="50%">
                <Box backgroundColor="#ccc" rounded="5px" p="5px" pl="10px">
                  Liability
                </Box>

                <Flex gap="20px" mt="20px">
                  <FormInput
                    section={SECTIONS.INSURANCE_COVERAGES}
                    label="Bodily Injury (limit)"
                    name="bodily_injury"
                    value={insurance_coverage.liability.bodily_injury.limit}
                    onChange={(e) =>
                      onInsuranceCoverageLiabilityChange(e, index, 'limit')
                    }
                  />
                  <FormInput
                    section={SECTIONS.INSURANCE_COVERAGES}
                    label="Bodily Injury (premium)"
                    name="bodily_injury"
                    value={insurance_coverage.liability.bodily_injury.premium}
                    onChange={(e) =>
                      onInsuranceCoverageLiabilityChange(e, index, 'premium')
                    }
                  />

                  <FormInput
                    section={SECTIONS.INSURANCE_COVERAGES}
                    label="Property Damage (premium)"
                    name="property_damage"
                    value={insurance_coverage.liability.property_damage.premium}
                    onChange={(e) =>
                      onInsuranceCoverageLiabilityChange(e, index, 'premium')
                    }
                  />
                </Flex>
              </Box>
              <Box w="25%">
                <Box backgroundColor="#ccc" rounded="5px" p="5px" pl="10px">
                  Accident Benefits
                </Box>

                <Flex gap="20px" mt="20px">
                  <FormInput
                    section={SECTIONS.INSURANCE_COVERAGES}
                    label="Accident Benefits (premium)"
                    name="accident_benefits"
                    value={insurance_coverage.accident_benefits.premium}
                    onChange={(e) =>
                      onInsuranceCovergaeAccidentBenefitsChange(e, index)
                    }
                  />
                </Flex>
              </Box>
              <Box w="25%">
                <Box backgroundColor="#ccc" rounded="5px" p="5px" pl="10px">
                  Uninsured Automobile
                </Box>

                <Flex gap="20px" mt="20px">
                  <FormInput
                    section={SECTIONS.INSURANCE_COVERAGES}
                    label="Uninsured Automobile (premium)"
                    name="uninsured_automobile"
                    value={insurance_coverage.uninsured_automobile.premium}
                    onChange={(e) =>
                      onInsuranceCoverageUninsuredAutomobileChange(e, index)
                    }
                  />
                </Flex>
              </Box>
            </Flex>

            {/* <Box
              backgroundColor="#ccc"
              rounded="5px"
              p="5px"
              pl="10px"
              mt="20px"
            >
              Optional Increased Accident Benefits
            </Box>

            <Box mt="20px">TBD...</Box> */}

            <Box
              backgroundColor="#ccc"
              rounded="5px"
              p="5px"
              pl="10px"
              mt="20px"
            >
              Direct Compensation
            </Box>

            <Flex gap="20px" mt="20px">
              <FormInput
                section={SECTIONS.INSURANCE_COVERAGES}
                label="Direct Compensation (deductible)"
                name="direct_compensation"
                value={insurance_coverage.direct_compensation.deductible}
                onChange={(e) =>
                  onInsuranceCoverageDirectCompensationChange(
                    e,
                    index,
                    'deductible',
                  )
                }
              />

              <FormInput
                section={SECTIONS.INSURANCE_COVERAGES}
                label="Direct Compensation (premium)"
                name="direct_compensation"
                value={insurance_coverage.direct_compensation.premium}
                onChange={(e) =>
                  onInsuranceCoverageDirectCompensationChange(
                    e,
                    index,
                    'premium',
                  )
                }
              />
            </Flex>

            <Box
              backgroundColor="#ccc"
              rounded="5px"
              p="5px"
              pl="10px"
              mt="20px"
            >
              Loss or Damage
            </Box>

            <Flex gap="20px" mt="20px" pl="20px">
              <Box w="50%">
                <Box backgroundColor="#ccc" rounded="5px" p="5px" pl="10px">
                  Specified Perils (excluding Collision or Upset)
                </Box>

                <Flex gap="20px" mt="20px">
                  <FormInput
                    section={SECTIONS.INSURANCE_COVERAGES}
                    label="Specified Perils (deductible)"
                    name="specified_perils"
                    value={
                      insurance_coverage.loss_or_damage.specified_perils
                        .deductible
                    }
                    onChange={(e) =>
                      onInsuranceCoverageLossOrDamageChange(
                        e,
                        index,
                        'deductible',
                      )
                    }
                  />

                  <FormInput
                    section={SECTIONS.INSURANCE_COVERAGES}
                    label="Specified Perils (premium)"
                    name="specified_perils"
                    value={
                      insurance_coverage.loss_or_damage.specified_perils.premium
                    }
                    onChange={(e) =>
                      onInsuranceCoverageLossOrDamageChange(e, index, 'premium')
                    }
                  />
                </Flex>
              </Box>
              <Box w="50%">
                <Box backgroundColor="#ccc" rounded="5px" p="5px" pl="10px">
                  Comprehensive (excluding Collision or Upset)
                </Box>

                <Flex gap="20px" mt="20px">
                  <FormInput
                    section={SECTIONS.INSURANCE_COVERAGES}
                    label="Comprehensive (deductible)"
                    name="comprehensive"
                    value={
                      insurance_coverage.loss_or_damage.comprehensive.deductible
                    }
                    onChange={(e) =>
                      onInsuranceCoverageLossOrDamageChange(
                        e,
                        index,
                        'deductible',
                      )
                    }
                  />

                  <FormInput
                    section={SECTIONS.INSURANCE_COVERAGES}
                    label="Comprehensive (premium)"
                    name="comprehensive"
                    value={
                      insurance_coverage.loss_or_damage.comprehensive.premium
                    }
                    onChange={(e) =>
                      onInsuranceCoverageLossOrDamageChange(e, index, 'premium')
                    }
                  />
                </Flex>
              </Box>
            </Flex>

            <Flex gap="20px" mt="20px" pl="20px">
              <Box w="50%">
                <Box backgroundColor="#ccc" rounded="5px" p="5px" pl="10px">
                  Collision or Upset
                </Box>

                <Flex gap="20px" mt="20px">
                  <FormInput
                    section={SECTIONS.INSURANCE_COVERAGES}
                    label="Collision or Upset (deductible)"
                    name="collision_or_upset"
                    value={
                      insurance_coverage.loss_or_damage.collision_or_upset
                        .deductible
                    }
                    onChange={(e) =>
                      onInsuranceCoverageLossOrDamageChange(
                        e,
                        index,
                        'deductible',
                      )
                    }
                  />

                  <FormInput
                    section={SECTIONS.INSURANCE_COVERAGES}
                    label="Collision or Upset (premium)"
                    name="collision_or_upset"
                    value={
                      insurance_coverage.loss_or_damage.collision_or_upset
                        .premium
                    }
                    onChange={(e) =>
                      onInsuranceCoverageLossOrDamageChange(e, index, 'premium')
                    }
                  />
                </Flex>
              </Box>
              <Box w="50%">
                <Box backgroundColor="#ccc" rounded="5px" p="5px" pl="10px">
                  All Perils
                </Box>

                <Flex gap="20px" mt="20px">
                  <FormInput
                    section={SECTIONS.INSURANCE_COVERAGES}
                    label="All Perils (deductible)"
                    name="all_perils"
                    value={
                      insurance_coverage.loss_or_damage.all_perils.deductible
                    }
                    onChange={(e) =>
                      onInsuranceCoverageLossOrDamageChange(
                        e,
                        index,
                        'deductible',
                      )
                    }
                  />

                  <FormInput
                    section={SECTIONS.INSURANCE_COVERAGES}
                    label="All Perils (premium)"
                    name="all_perils"
                    value={insurance_coverage.loss_or_damage.all_perils.premium}
                    onChange={(e) =>
                      onInsuranceCoverageLossOrDamageChange(e, index, 'premium')
                    }
                  />
                </Flex>
              </Box>
            </Flex>

            <Box
              backgroundColor="#ccc"
              rounded="5px"
              p="5px"
              pl="10px"
              mt="20px"
              mb="20px"
            >
              Policy Change Forms
            </Box>

            <FormInput
              section={SECTIONS.INSURANCE_COVERAGES}
              label="Family Protection Coverage - OPCF 44R (premium)"
              name="family_protection_coverage"
              value={
                insurance_coverage.policy_change_forms
                  .family_protection_coverage.premium
              }
              onChange={(e) =>
                onPolicyChangeFormsFamilyProtectionCoverageChange(e, index)
              }
            />

            {insurance_coverage.policy_change_forms.other_coverages.map(
              (other_coverage, otherCoverageIndex) => {
                return (
                  <Flex gap="20px" mt="20px" key={otherCoverageIndex}>
                    <FormInput
                      section={SECTIONS.INSURANCE_COVERAGES}
                      label="Name"
                      name="name"
                      value={other_coverage.name}
                      onChange={(e) =>
                        onPolicyChangeFormsOtherCoveragesChange(
                          e,
                          index,
                          otherCoverageIndex,
                        )
                      }
                    />

                    <FormInput
                      section={SECTIONS.INSURANCE_COVERAGES}
                      label="Deductible/limit"
                      name="deductible_limit"
                      value={other_coverage.deductible_limit}
                      onChange={(e) =>
                        onPolicyChangeFormsOtherCoveragesChange(
                          e,
                          index,
                          otherCoverageIndex,
                        )
                      }
                    />

                    <FormInput
                      section={SECTIONS.INSURANCE_COVERAGES}
                      label="Premium"
                      name="premium"
                      value={other_coverage.premium}
                      onChange={(e) =>
                        onPolicyChangeFormsOtherCoveragesChange(
                          e,
                          index,
                          otherCoverageIndex,
                        )
                      }
                    />

                    <Button
                      mt="30px"
                      onClick={(e) =>
                        dispatch(
                          removePolicyChangeFormsOtherCoverages({
                            index,
                            subIndex: otherCoverageIndex,
                          }),
                        )
                      }
                      disabled={isDisabled}
                    >
                      <DeleteIcon />
                    </Button>
                  </Flex>
                );
              },
            )}

            <Box mt="20px">
              <Button
                variant="brand"
                onClick={() =>
                  dispatch(addPolicyChangeFormsOtherCoverages(index))
                }
                disabled={isDisabled}
              >
                Add coverage
              </Button>
            </Box>
          </Box>
        );
      })}
    </Card>
  );
};

export default InsuranceCoverages;
