import { useContext } from 'react';

import { useAppSelector } from 'hooks';

// @ts-ignore
import { HashLink } from 'react-router-hash-link';

import {
  Alert,
  AlertIcon,
  Box,
  AlertTitle,
  AlertDescription,
  SimpleGrid,
  Icon,
  Text,
} from '@chakra-ui/react';

import { CheckCircleIcon } from '@chakra-ui/icons';

import { ApplicationFormContext } from 'contexts/ApplicationFormContext';

import capitalizeFirstLetterInAllWords from '../../helpers/capitalizeFirstLetterInAllWords';

import { SUBMISSION_STATUS_FAILED } from 'variables/constants';

import { FILE_TYPE } from 'views/submission/constants';

const MandatoryFields = () => {
  const { validations } = useContext(ApplicationFormContext) as {
    validations: Validation[];
  };

  const missingFields = useAppSelector(
    (state) => state.submissionForm.missingMandatoryFields,
  );

  const missingRemarks = useAppSelector(
    (state) => state.submissionForm.missingRemarks,
  );

  const missingUploads = useAppSelector(
    (state) => state.submissionForm.missingUploads,
  );

  const submissionStatus = useAppSelector(
    (state) => state.submissionForm.status,
  );

  const isMandatoryFieldsFilled = missingFields.length === 0;
  const isRemarksFilled = Object.keys(missingRemarks).length === 0;
  const isUploadsFilled = Object.keys(missingUploads).length === 0;

  const scrollWithOffset = (el: any, yOffset: number) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const getValidationName = (id: string) => {
    const validation = validations.find((v) => v.validation_id === id);

    if (validation) return validation.validation_name;

    const name = id.replace(/_/g, ' ');

    return capitalizeFirstLetterInAllWords(name);
  };

  const getFileName = (fileType: number) => {
    return Object.values(FILE_TYPE).find((type) => type.type === fileType).text;
  };

  const getSubFieldName = (name: string) => {
    if (name === 'global') return '';
    const newName = capitalizeFirstLetterInAllWords(name.replace(/-/g, ' '));
    return ` (${newName})`;
  };

  const getNormalizedFields = (data: any) => {
    let result = [] as any;

    for (let validationId in data) {
      if (data.hasOwnProperty(validationId)) {
        const entry = data[validationId];

        entry.missing.forEach((missingKey: any) => {
          result.push({
            validationId: validationId,
            key: missingKey,
            fileType: entry?.file_type,
          });
        });
      }
    }

    return result;
  };

  const getToURL = (validationId: string, key: string) => {
    if (key === 'global') {
      return `#${validationId}`;
    }

    return `#${validationId}-${key}`;
  };

  if (submissionStatus === SUBMISSION_STATUS_FAILED) {
    return null;
  }

  return (
    <SimpleGrid columns={3} gap="20px">
      <Alert
        status={isMandatoryFieldsFilled ? 'success' : 'warning'}
        mb="20px"
        alignItems={isMandatoryFieldsFilled ? 'center' : 'top'}
      >
        {!isMandatoryFieldsFilled && <AlertIcon />}
        <Box w="100%">
          <AlertTitle textAlign={isMandatoryFieldsFilled ? 'center' : 'left'}>
            {isMandatoryFieldsFilled && (
              <Icon
                w="20px"
                h="20px"
                mr="10px"
                color="green.500"
                as={CheckCircleIcon}
              />
            )}
            {isMandatoryFieldsFilled
              ? 'Mandatory fields completed'
              : 'Missing Mandatory fields'}
          </AlertTitle>

          {!isMandatoryFieldsFilled && (
            <AlertDescription>
              <Box mt="5px">
                {missingFields.map((field, index) => (
                  <HashLink
                    key={index}
                    smooth
                    to={`#${field['name']}`}
                    scroll={(el: any) => scrollWithOffset(el, -184)}
                  >
                    <Text
                      textDecoration={'none'}
                      _hover={{ textDecoration: 'underline' }}
                    >
                      {field['name']}
                    </Text>
                  </HashLink>
                ))}
              </Box>
            </AlertDescription>
          )}
        </Box>
      </Alert>

      <Alert
        status={isRemarksFilled ? 'success' : 'warning'}
        mb="20px"
        alignItems={isRemarksFilled ? 'center' : 'top'}
      >
        {!isRemarksFilled && <AlertIcon />}
        <Box w="100%">
          <AlertTitle textAlign={isRemarksFilled ? 'center' : 'left'}>
            {isRemarksFilled && (
              <Icon
                w="20px"
                h="20px"
                mr="10px"
                color="green.500"
                as={CheckCircleIcon}
              />
            )}
            {isRemarksFilled ? 'Remarks completed' : 'Missing remarks'}
          </AlertTitle>
          {!isRemarksFilled && (
            <AlertDescription>
              <Box mt="5px">
                {getNormalizedFields(missingRemarks).map(
                  (field: any, index: any) => (
                    <HashLink
                      key={index}
                      smooth
                      to={getToURL(field['validationId'], field['key'])}
                      scroll={(el: any) => scrollWithOffset(el, -200)}
                    >
                      <Text
                        textDecoration={'none'}
                        _hover={{ textDecoration: 'underline' }}
                      >
                        {getValidationName(field['validationId'])}
                        {getSubFieldName(field['key'])}
                      </Text>
                    </HashLink>
                  ),
                )}
              </Box>
            </AlertDescription>
          )}
        </Box>
      </Alert>

      <Alert
        status={isUploadsFilled ? 'success' : 'warning'}
        mb="20px"
        alignItems={isUploadsFilled ? 'center' : 'top'}
      >
        {!isUploadsFilled && <AlertIcon />}
        <Box w="100%">
          <AlertTitle textAlign={isUploadsFilled ? 'center' : 'left'}>
            {isUploadsFilled && (
              <Icon
                w="20px"
                h="20px"
                mr="10px"
                color="green.500"
                as={CheckCircleIcon}
              />
            )}
            {isUploadsFilled ? 'Uploads completed' : 'Missing uploads'}
          </AlertTitle>
          {!isUploadsFilled && (
            <AlertDescription>
              <Box mt="5px">
                {getNormalizedFields(missingUploads).map(
                  (field: any, index: any) => (
                    <HashLink
                      key={index}
                      smooth
                      to={getToURL(field['validationId'], field['key'])}
                      scroll={(el: any) => scrollWithOffset(el, -200)}
                    >
                      <Text
                        textDecoration={'none'}
                        _hover={{ textDecoration: 'underline' }}
                      >
                        {getFileName(field['fileType'])}
                        {getSubFieldName(field['key'])}
                      </Text>
                    </HashLink>
                  ),
                )}
              </Box>
            </AlertDescription>
          )}
        </Box>
      </Alert>
    </SimpleGrid>
  );
};

export default MandatoryFields;
