import { useState } from 'react';

import { useAppSelector } from 'hooks';

import { Box, Text, Icon } from '@chakra-ui/react';

import { QuestionIcon } from '@chakra-ui/icons';

import { HamburgerIcon } from '@chakra-ui/icons';

// @ts-ignore
import { HashLink } from 'react-router-hash-link';

import { SECTIONS as SECTION_TYPES } from 'views/submission/constants';

const SECTIONS = [
  {
    name: 'Policy Information',
    link: 'sectionPolicyInformation',
    section: SECTION_TYPES.POLICY_INFORMATION,
  },
  {
    name: 'Insurance Company and Broker/Agent Information',
    link: 'sectionInsuranceCompanyInformation',
    section: SECTION_TYPES.INSURANCE_COMPANY_INFORMATION,
  },
  {
    name: "Applicant's Name & Primary Address",
    link: 'sectionApplicant',
    section: SECTION_TYPES.APPLICANT,
  },
  {
    name: 'Lessor (if applicable)',
    link: 'sectionLessor',
    section: SECTION_TYPES.LESSOR,
  },
  {
    name: 'Policy Period',
    link: 'sectionPolicyPeriod',
    section: SECTION_TYPES.POLICY_PERIOD,
  },
  {
    name: 'Described Automobile',
    link: 'sectionVehicles',
    section: SECTION_TYPES.VEHICLES,
  },
  {
    name: 'Driver Information',
    link: 'sectionDrivers',
    section: SECTION_TYPES.DRIVERS,
  },
  {
    name: 'Previous Accidents and Insurance Claims',
    link: 'sectionClaims',
    section: SECTION_TYPES.CLAIMS,
  },
  {
    name: 'History of Convictions',
    link: 'sectionConvictions',
    section: SECTION_TYPES.CONVICTIONS,
  },
  {
    name: 'Rating Information - AGENT/BROKER AND COMPANY USE ONLY',
    link: 'sectionRatingInformation',
    section: SECTION_TYPES.RATING_INFORMATION,
  },
  {
    name: 'Insurance Coverages Applied For',
    link: 'sectionInsuranceCoverages',
    section: SECTION_TYPES.INSURANCE_COVERAGES,
  },
  {
    name: 'Method of Payment',
    link: 'sectionMethodOfPayment',
    section: SECTION_TYPES.METHOD_OF_PAYMENT,
  },
  {
    name: 'Declaration of Applicant',
    link: 'sectionDeclarationOfApplicant',
    section: SECTION_TYPES.DECLARATION_OF_APPLICANT,
  },
  {
    name: 'Report of Broker/Agent',
    link: 'sectionReportOfBrokerAgent',
    section: SECTION_TYPES.REPORT_OF_BROKER_AGENT,
  },
  {
    name: 'Personal Consent',
    link: 'sectionPersonalConsent',
    section: SECTION_TYPES.PERSONAL_CONSENT,
  },
  { name: 'Remarks', link: 'sectionRemarks', section: SECTION_TYPES.REMARKS },
];

const SectionMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const missingFields = useAppSelector(
    (state) => state.submissionForm.missingMandatoryFields,
  );

  const missingRemarks = useAppSelector(
    (state) => state.submissionForm.missingRemarks,
  );

  const missingUploads = useAppSelector(
    (state) => state.submissionForm.missingUploads,
  );

  const scrollWithOffset = (el: any) => {
    setIsOpen(false);
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -164;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const hasMissingData = (section: number) => {
    if (!section) return false;

    // Look for section ID in missing fields
    if (missingFields.some((field) => field.section === section)) {
      return true;
    }

    // Look for section ID in missing remarks
    if (
      Object.values(missingRemarks).some((item) => item.section === section)
    ) {
      return true;
    }

    // Look for section ID in missing uploads
    if (
      Object.values(missingUploads).some((item) => item.section === section)
    ) {
      return true;
    }

    return false;
  };

  return (
    <Box
      position="fixed"
      w={{
        base: 'calc(100% - 40px)',
        xl: 'calc(100% - 340px)',
      }}
      zIndex={1}
      cursor="pointer"
      onClick={() => setIsOpen(!isOpen)}
      top="105px"
    >
      <Box
        backgroundColor="brand.400"
        color="white"
        p="10px"
        pl="20px"
        pr="20px"
        borderBottomRadius="10px"
      >
        <Text fontWeight="bold" fontSize="l">
          Navigation
          <Box float="right">
            <HamburgerIcon />
          </Box>
        </Text>
        {isOpen && (
          <Box mt="10px">
            {SECTIONS.map((section, index) => (
              <Box key={index}>
                <HashLink
                  smooth
                  to={`#${section.link}`}
                  scroll={scrollWithOffset}
                >
                  <Text
                    textDecoration={'none'}
                    _hover={{ textDecoration: 'underline' }}
                  >
                    {section.name}
                    {hasMissingData(section.section) && (
                      <Icon
                        w="20px"
                        h="20px"
                        ml="10px"
                        color="orange.500"
                        as={QuestionIcon}
                      />
                    )}
                  </Text>
                </HashLink>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SectionMenu;
