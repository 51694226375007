import { useContext } from 'react';

import { useAppSelector, useAppDispatch } from 'hooks';

import { Text, OrderedList, ListItem, Box } from '@chakra-ui/react';

import Card from 'components/card/Card';

import TextFieldInput from '../../components/TextFieldInput';

import { ApplicationFormContext } from 'contexts/ApplicationFormContext';

import capitalizeFirstLetterInAllWords from '../../helpers/capitalizeFirstLetterInAllWords';

import Message from '../../components/Message';
import getValidationMessage from '../../components/Message/getValidationMessage';

import { changeRemarks } from 'reducers/submissionFormReducer';

import { SECTIONS } from 'views/submission/constants';

const Remarks = () => {
  const dispatch = useAppDispatch();

  const { validations } = useContext(ApplicationFormContext) as {
    validations: Validation[];
  };

  const remarks = useAppSelector((state) => state.submissionForm.remarks);

  const getSubRemarks = (key: string, remakrs: any): string[] => {
    const valdiation_id = key.replace('remark-', '');
    const validation = validations.find(
      (v) => Number(v.validation_id) === Number(valdiation_id),
    );
    const validation_name =
      validation?.validation_name ||
      capitalizeFirstLetterInAllWords(
        key.replace('remark-', '').replaceAll('_', ' '),
      );

    const subRemarks = Object.entries(remakrs).map(([key, text], index) => {
      if (key.startsWith('vehicle-')) {
        const vehicle_nr = key.replace('vehicle-', '');
        return `${validation_name} (vehicle ${vehicle_nr}) Remarks - ${text}`;
      }

      if (key.startsWith('driver-')) {
        const driver_nr = key.replace('driver-', '');
        return `${validation_name} (driver ${driver_nr}) Remarks - ${text}`;
      }

      return `${validation_name} Remarks - ${text}`;
    });

    return subRemarks;
  };

  const onGlobalRemarksChange = (event: any) => {
    const { name, value } = event.target;

    dispatch(changeRemarks({ name, value, key: 'global' }));
  };

  return (
    <Card mb="20px" id="sectionRemarks">
      <Text fontWeight="bold" fontSize="2xl" mb="20px">
        Remarks
      </Text>

      {getValidationMessage(validations, '077') && (
        <Box mb="20px">
          <Message validationId="077" section={SECTIONS.REMARKS} />
        </Box>
      )}

      {getValidationMessage(validations, '087') && (
        <Box mb="20px">
          <Message validationId="087" section={SECTIONS.REMARKS} />
        </Box>
      )}

      {getValidationMessage(validations, '006') && (
        <Box mb="20px">
          <Message validationId="006" section={SECTIONS.REMARKS} />
        </Box>
      )}

      {!Object.keys(remarks).length && <div>Empty...</div>}

      <OrderedList spacing="1">
        {Object.entries(remarks).map(([key, value], index) => {
          return (
            <div key={key}>
              {getSubRemarks(key, value).map((text, index) => {
                return <ListItem key={index}>{text}</ListItem>;
              })}
            </div>
          );
        })}
      </OrderedList>

      <Box mt="20px">
        <TextFieldInput
          label="Additional Remarks"
          value={remarks?.additional?.global}
          onChange={onGlobalRemarksChange}
          name="additional"
          maxH="auto"
        />
      </Box>
    </Card>
  );
};

export default Remarks;
