import { mode } from '@chakra-ui/theme-tools';

export const buttonStyles = {
  components: {
    Button: {
      baseStyle: {
        borderRadius: '16px',
        boxShadow: '45px 76px 113px 7px rgba(112, 144, 176, 0.08)',
        transition: '.25s all ease',
        boxSizing: 'border-box',
        _focus: {
          boxShadow: 'none',
        },
        _active: {
          boxShadow: 'none',
        },
      },
      variants: {
        outline: () => ({
          borderRadius: '16px',
        }),
        green: (props: any) => ({
          bg: mode('green.500', 'green.500')(props),
          color: 'brand.400',
          _focus: {
            bg: mode('secondaryGray.400', 'whiteAlpha.200')(props),
          },
          _active: {
            bg: mode('secondaryGray.300', 'whiteAlpha.100')(props),
            _disabled: {
              bg: mode('green.500', 'green.500')(props),
            },
          },
          _hover: {
            bg: mode('secondaryGray.400', 'whiteAlpha.200')(props),
            _disabled: {
              bg: mode('green.500', 'green.500')(props),
              color: 'brand.400',
            },
          },
        }),
        brand: (props: any) => ({
          bg: mode('brand.500', 'brand.400')(props),
          color: 'white',
          _focus: {
            bg: mode('brand.500', 'brand.400')(props),
          },
          _active: {
            bg: mode('brand.500', 'brand.400')(props),
          },
          _hover: {
            backgroundColor: '#CBD5E0',
            color: 'brand.400',
            _disabled: {
              bg: mode('brand.500', 'brand.400')(props),
            },
          },
        }),
        yellow: (props: any) => ({
          bg: mode('orange.500', 'orange.500')(props),
          _focus: {
            bg: mode('secondaryGray.400', 'whiteAlpha.200')(props),
          },
          _active: {
            bg: mode('secondaryGray.300', 'whiteAlpha.100')(props),
            _disabled: {
              bg: mode('orange.500', 'orange.500')(props),
            },
          },
          _hover: {
            bg: mode('secondaryGray.400', 'whiteAlpha.200')(props),
            _disabled: {
              bg: mode('orange.500', 'orange.500')(props),
            },
          },
        }),
      },
    },
  },
};
