import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  CloseButton,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  Box,
  Image,
  UnorderedList,
  ListItem,
  Link,
} from '@chakra-ui/react';

import Card from 'components/card/Card';
import ValidationMessage from 'components/validation/ValidationMessage';

import { MdChevronRight } from 'react-icons/md';

import loader from 'assets/img/others/loader.gif';

import { AuthContext } from 'contexts/AuthContext';

import { splitValidationText } from 'utils/utils';

import Storage from 'storage';

const MotionAlert = motion(Alert);

type FlagsState = {
  [key: string]: boolean;
};

export default function ValidatedPolicy({
  custom_auth,
}: {
  custom_auth?: {
    token: string;
    endpoint: string;
  };
}) {
  const { organization, user } = useContext(AuthContext);
  const { uuid } = useParams();
  const [validations, setValidations] = useState([]);
  const [selectedValidation, setSelectedValidation] = useState(null);
  const [flags, setFlags] = useState<FlagsState>({});
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;
  const caution = '#ffda87';
  const fail = '#f8a586';
  const pass = '#badeb5';
  const [numPass, setNumPass] = useState(0);
  const [numCaution, setNumCaution] = useState(0);
  const [numFail, setNumFail] = useState(0);
  const [errors, setErrors] = useState(null);

  const handleClick = (validation: any) => {
    setFlags((prev) => ({
      ...prev,
      [validation.validation_id]: !prev[validation.validation_id],
    }));
    setSelectedValidation(validation);
  };

  const handleClose = (validation: any) => {
    setFlags((prev) => ({
      ...prev,
      [validation.validation_id]: !prev[validation.validation_id],
    }));
    setSelectedValidation(null);
  };

  const getPageData = (some: any[]) => {
    const startIndex = (currentPage - 1) * pageSize;
    const pageData = some?.slice(startIndex, startIndex + pageSize);
    return {
      leftColumn: pageData.slice(0, pageData.length / 2),
      rightColumn: pageData.slice(pageData.length / 2),
    };
  };

  const [filterType, setFilterType] = useState(null);

  const clickFilter = (type: string) => {
    setFilterType(type);
  };

  const navigate = useNavigate();
  const [arr, setArr] = useState([]);

  const handleRedirect = () => {
    navigate(`/admin/policies/submission/${uuid}`);
    window.scrollTo(0, 0);
  };

  const fetchValidations = () => {
    const token = custom_auth?.token || organization.token;
    const endpoint = custom_auth?.endpoint || Storage.get('API_ENDPOINT');

    fetch(`${endpoint}api/policy/mc/${uuid}`, {
      headers: {
        Token: token,
        User: user?.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.validations) {
          setValidations(data.validations);
          setArr(data.validations);
          return;
        }

        if (data?.error) {
          setErrors(data.error);
          return;
        }

        setTimeout(fetchValidations, 2000);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    let filteredValidations = validations;
    if (filterType === null) {
      filteredValidations = validations;
    } else if (filterType === 'all') {
      filteredValidations = validations;
    } else {
      filteredValidations = validations.filter(
        (v) => v.validation_outcome === filterType,
      );
    }

    setArr(filteredValidations);
  }, [filterType, validations]);

  useEffect(() => {
    if (!uuid) return;
    fetchValidations();
  }, [uuid]);

  const totalPages = Math.ceil(validations?.length / pageSize);

  const handlePagination = (direction: string) => {
    setCurrentPage((prev) =>
      direction === 'next'
        ? Math.min(prev + 1, totalPages)
        : Math.max(prev - 1, 1),
    );
  };

  useEffect(() => {
    let pass = 0;
    let fail = 0;
    let caution = 0;
    validations?.forEach((v) => {
      switch (v.validation_outcome) {
        case 'pass':
          pass += 1;
          break;
        case 'fail':
          fail += 1;
          break;
        case 'caution':
          caution += 1;
          break;
        default:
          break;
      }
      setNumPass(pass);
      setNumFail(fail);
      setNumCaution(caution);
    });
  }, [validations]);

  const [showAllDetails, setShowAllDetails] = useState(false);

  const handleToggleDetails = () => {
    setShowAllDetails((prev) => !prev);
  };

  // TODO: Refactor this on API level to get back runner type
  const isViewOnly = validations.some((val) => val.validation_id === '001');

  const renderColumn = (data: any[]) =>
    data.map((validation) => {
      const {
        validation_id,
        validation_outcome,
        validation_name,
        description,
        validation_message,
      } = validation;
      const outcomeColor =
        validation_outcome === 'pass'
          ? pass
          : validation_outcome === 'caution'
          ? caution
          : fail;
      const alertStatus =
        validation_outcome === 'pass'
          ? 'success'
          : validation_outcome === 'caution'
          ? 'warning'
          : 'error';

      return (
        <div key={validation_id}>
          <Alert
            backgroundColor={outcomeColor}
            status={alertStatus}
            variant="solid"
            justifyContent="center"
            borderRadius="8px"
            alignItems="center"
            onClick={() => handleClick(validation)}
            cursor="pointer"
          >
            <AlertIcon color="#59606A" />
            <AlertTitle color="#59606A" mr="12px">
              {validation_outcome.charAt(0).toUpperCase() +
                validation_outcome.slice(1)}
            </AlertTitle>
            <AlertDescription color="#59606A">
              {validation_name}
            </AlertDescription>
            <Icon
              as={MdChevronRight}
              color="#59606A"
              h="30px"
              w="30px"
              position="absolute"
              right="10px"
              top="9px"
              transform={
                flags[validation_id] ? 'rotate(90deg)' : 'rotate(0deg)'
              }
              transition="transform 0.3s ease-in-out"
            />
          </Alert>
          {(flags[validation_id] && selectedValidation === validation) ||
          showAllDetails ? (
            <MotionAlert
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.8 }}
              borderRadius="8px"
              status={alertStatus}
              backgroundColor={
                validation_outcome === 'pass'
                  ? '#EBF0EB'
                  : validation_outcome === 'caution'
                  ? '#fffbe5'
                  : '#ffe1d5'
              }
              mt="1rem"
            >
              <Flex>
                <AlertIcon color={outcomeColor} />
                <Flex direction="column">
                  <AlertTitle
                    color="#59606A"
                    mr="12px"
                    fontWeight={700}
                    mb={alertStatus !== 'success' ? '10px' : '0'}
                  >
                    {description}
                  </AlertTitle>
                  <AlertDescription color="#59606A" fontWeight={500}>
                    <ValidationMessage
                      message={validation.validation_message}
                      boldFirst={false}
                    />
                  </AlertDescription>
                </Flex>
              </Flex>
              <CloseButton
                position="absolute"
                right="8px"
                top="8px"
                onClick={() => handleClose(validation)}
              />
            </MotionAlert>
          ) : null}
        </div>
      );
    });

  if (errors) {
    return (
      <Card>
        <Text fontWeight="bold" fontSize="2xl" mb="20px">
          Errors detected while parsing files
        </Text>

        <Alert status="error">
          <AlertIcon />
          <AlertDescription>
            <UnorderedList>
              {errors.map((error: any) => {
                return (
                  <div>
                    {Object.entries(error).map((entry: any) => (
                      <ListItem>
                        <strong style={{ textTransform: 'capitalize' }}>
                          {entry[0]}
                        </strong>{' '}
                        - {entry[1]}
                      </ListItem>
                    ))}
                  </div>
                );
              })}
            </UnorderedList>
          </AlertDescription>
        </Alert>

        <Text mt="20px">
          Please make corrections and{' '}
          <Link
            color="teal.500"
            onClick={() => navigate('/admin/policies/new')}
          >
            Create New Policy
          </Link>
        </Text>

        <Text mt="20px">Reference ID: {uuid}</Text>
      </Card>
    );
  }

  if (validations.length === 0) {
    return (
      <Box textAlign="center" mt="50px">
        <Image src={loader} style={{ margin: '0 auto' }}></Image>
        <Text mt="30px" fontSize="xl" color="#59606A">
          Checking Policy Info Against Underwriting Guidelines...
        </Text>
      </Box>
    );
  }

  return (
    <>
      <Card>
        <Heading size="lg" textAlign={'center'} mb="40px" color="#59606A">
          Summary View
        </Heading>

        <Flex justifyContent="space-evenly">
          <Button
            onClick={() => clickFilter('pass')}
            color="#59606A"
            textAlign={'center'}
            size="lg"
            padding="4rem"
            w="3rem"
            background={pass}
            borderRadius="50%"
          >
            {numPass} Pass
          </Button>
          <Button
            onClick={() => clickFilter('caution')}
            color="#59606A"
            textAlign={'center'}
            size="lg"
            padding="4rem"
            w="3rem"
            background={caution}
            borderRadius="50%"
          >
            {numCaution} Caution
          </Button>
          <Button
            onClick={() => clickFilter('fail')}
            color="#59606A"
            textAlign={'center'}
            size="lg"
            padding="4rem"
            w="3rem"
            background={fail}
            borderRadius="50%"
          >
            {numFail} Fail
          </Button>
        </Flex>

        <Flex alignItems="center" justifyContent="center" mt="20px">
          <Button
            fontSize="lg"
            variant="brand"
            fontWeight="500"
            w="50%"
            mr="1%"
            h="50"
            mt="30px"
            onClick={handleToggleDetails}
          >
            Toggle Details
          </Button>
          <Button
            onClick={() => clickFilter('all')}
            fontSize="lg"
            backgroundColor="#BFC3C8"
            fontWeight="500"
            w="50%"
            h="50"
            mt="30px"
          >
            See All
          </Button>
        </Flex>
      </Card>

      <Card w="100%" mt="20px" alignSelf="center" mb="20px">
        <Flex align="right" w="100%" justifyContent="space-between">
          <Text fontSize="2xl" mb="20px" fontWeight="bold" color="#59606A">
            Please click to reveal more information.
          </Text>
        </Flex>
        <Flex direction="row" justify="space-between" wrap="wrap">
          <Stack spacing="25px" w="47%">
            {renderColumn(getPageData(arr).leftColumn)}
          </Stack>
          <Stack w="5%"></Stack>
          <Stack spacing="25px" w="47%">
            {renderColumn(getPageData(arr).rightColumn)}
          </Stack>
        </Flex>

        <Flex justify="center" mt="20px" alignItems={'center'}>
          <Button
            onClick={() => handlePagination('prev')}
            disabled={currentPage === 1}
          >
            Prev
          </Button>
          <Text mx="4" textAlign={'center'}>
            Page {currentPage} of {totalPages}
          </Text>
          <Button
            onClick={() => handlePagination('next')}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </Flex>

        {!isViewOnly && (
          <Button
            mt="20px"
            alignSelf="center"
            fontSize="lg"
            variant="green"
            fontWeight="500"
            w="100%"
            h="50"
            onClick={handleRedirect}
          >
            Autofill Application and Start Submission
          </Button>
        )}
      </Card>
    </>
  );
}
