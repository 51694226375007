import { useContext } from 'react';

import { Route, Routes, Navigate } from 'react-router-dom';

import { Portal, Box } from '@chakra-ui/react';

import TopNavigation from 'components/navbar/TopNavigation';
import Sidebar from 'components/sidebar/Sidebar';
import Footer from 'components/footer/FooterAdmin';

import routes from 'routes';

import { AuthContext } from 'contexts/AuthContext';

const AdminLayout = ({ theme, setTheme, ...rest }: { [x: string]: any }) => {
  const { organization } = useContext(AuthContext);

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: number) => {
      if (
        route?.permissions &&
        !route.permissions.includes(organization?.role)
      ) {
        return null;
      }

      if (route.layout === '/admin') {
        return (
          <Route path={`${route.path}`} element={route.component} key={key} />
        );
      }

      if (route.collapse) {
        return getRoutes(route.items);
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <Sidebar />

      <Box
        float="right"
        position="relative"
        w={{ base: '100%', xl: 'calc( 100% - 300px )' }}
      >
        <Portal>
          <TopNavigation theme={theme} setTheme={setTheme} {...rest} />
        </Portal>

        <Box px="20px" pt={{ base: '180px', md: '120px' }} minH="100vh">
          <Routes>
            {getRoutes(routes)}
            <Route
              path="*"
              element={<Navigate to="/admin/policies/history" replace />}
            />
          </Routes>
        </Box>

        <Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default AdminLayout;
