import { Box, Flex, Button } from '@chakra-ui/react';

import { DeleteIcon } from '@chakra-ui/icons';

import FormInput from 'views/submission/components/FormInput';
import CheckBoxInput from 'views/submission/components/CheckBoxInput';
import DateInput from 'views/submission/components/DateInput';

import getDateFromString from 'views/submission/helpers/getDateFromString';

import type { APIApplicationClaim } from 'types/api.types';

import { SECTIONS } from 'views/submission/constants';

const Claim = ({
  index,
  claim,
  isDisabled,
  onChange,
  onDateChange,
  onRemoveClaim,
}: {
  index: number;
  claim: APIApplicationClaim;
  isDisabled: boolean;
  onChange: (e: any, index: number) => void;
  onDateChange: (date: Date, name: string, index: number) => void;
  onRemoveClaim: (e: any, index: number) => void;
}) => {
  return (
    <Flex gap="20px" mt="20px">
      <Flex w="6%">
        <FormInput
          section={SECTIONS.CLAIMS}
          label="Driver(No.)"
          name="driver_nr"
          value={claim.driver_nr}
          onChange={(e) => onChange(e, index)}
        />
      </Flex>

      <Flex w="6%">
        <FormInput
          section={SECTIONS.CLAIMS}
          label="Auto(No.)"
          name="auto_nr"
          value={claim.auto_nr}
          onChange={(e) => onChange(e, index)}
        />
      </Flex>

      <Flex w="10%">
        <DateInput
          section={SECTIONS.CLAIMS}
          label="Date"
          date={getDateFromString(claim.date)}
          onChange={(date: Date) => onDateChange(date, 'date', index)}
        />
      </Flex>

      <Flex w="35%" flexFlow="wrap">
        <Flex w="100%">
          <Box
            w="100%"
            textAlign="center"
            borderBottom="1px solid #ccc"
            mb="10px"
          >
            Coverage Claim Paid Under
          </Box>
        </Flex>
        <Flex gap="20px" flexFlow="wrap">
          <CheckBoxInput
            section={SECTIONS.CLAIMS}
            name="is_bi"
            isChecked={claim.is_bi}
            onChange={(e) => onChange(e, index)}
          >
            BI
          </CheckBoxInput>

          <CheckBoxInput
            section={SECTIONS.CLAIMS}
            name="is_pd"
            isChecked={claim.is_pd}
            onChange={(e) => onChange(e, index)}
          >
            PD
          </CheckBoxInput>

          <CheckBoxInput
            section={SECTIONS.CLAIMS}
            name="is_ab"
            isChecked={claim.is_ab}
            onChange={(e) => onChange(e, index)}
          >
            AB
          </CheckBoxInput>

          <CheckBoxInput
            section={SECTIONS.CLAIMS}
            name="is_dcpd"
            isChecked={claim.is_dcpd}
            onChange={(e) => onChange(e, index)}
          >
            DCPD
          </CheckBoxInput>

          <CheckBoxInput
            section={SECTIONS.CLAIMS}
            name="is_ua"
            isChecked={claim.is_ua}
            onChange={(e) => onChange(e, index)}
          >
            UA
          </CheckBoxInput>

          <CheckBoxInput
            section={SECTIONS.CLAIMS}
            name="is_coll_ap"
            isChecked={claim.is_coll_ap}
            onChange={(e) => onChange(e, index)}
          >
            Coll/AP
          </CheckBoxInput>

          <CheckBoxInput
            section={SECTIONS.CLAIMS}
            name="is_comp_sp"
            isChecked={claim.is_comp_sp}
            onChange={(e) => onChange(e, index)}
          >
            Comp/SP
          </CheckBoxInput>
        </Flex>
      </Flex>

      <Flex w="10%">
        <FormInput
          section={SECTIONS.CLAIMS}
          label="Amount Paid "
          name="paid"
          value={claim.paid}
          onChange={(e) => onChange(e, index)}
        />
      </Flex>

      <Flex w="20%">
        <FormInput
          section={SECTIONS.CLAIMS}
          label="Details"
          name="details"
          value={claim.details}
          onChange={(e) => onChange(e, index)}
        />
      </Flex>

      <Flex w="5%">
        <Button
          mt="30px"
          onClick={(e) => onRemoveClaim(e, index)}
          disabled={isDisabled}
        >
          <DeleteIcon />
        </Button>
      </Flex>
    </Flex>
  );
};

export default Claim;
