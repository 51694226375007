import { useState, useContext, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import {
  Box,
  Flex,
  Icon,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';

import {
  QuestionIcon,
  WarningIcon,
  CheckCircleIcon,
  CalendarIcon,
} from '@chakra-ui/icons';

import { Paginate } from 'react-paginate-chakra-ui';

// @ts-ignore
import { DateRangePicker } from 'react-date-range';

import Card from 'components/card/Card';

import { AuthContext } from 'contexts/AuthContext';

import { apiPolicyList } from 'api';

import config from 'config';

import getDateString from 'helpers/getDateString';
import getDateRangeLabel from 'helpers/getDateRangeLabel';

import type {
  ApiPolicyListData,
  APIPolicyListResultData,
} from 'types/api.types';

const TABLE_HEADERS = [
  'Status',
  'Policy No.',
  'Date',
  'Broker Name',
  'Premium',
  'Carrier',
  'Underwriting Score',
  'Progress',
];

const ValidatedPoliciesView = () => {
  const { organization } = useContext(AuthContext);
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [periodDates, setPeriodDates] = useState({
    startDate: null,
    endDate: null,
    key: 'selection',
  });
  const [analyticsKey, setAnalyticsKey] = useState('initial');
  const [isAnalyticsModalOpen, setIsAnalyticsModalOpen] = useState(false);

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const { error, data, isLoading } = useQuery({
    queryKey: ['policy-list', page + analyticsKey],
    queryFn: () =>
      apiPolicyList(
        page + 1,
        getDateString(periodDates.startDate),
        getDateString(periodDates.endDate),
      ),
  }) as {
    data: ApiPolicyListData;
    isLoading: boolean;
    error: any;
  };

  const onPolicyClick = (policy: APIPolicyListResultData) => {
    navigate(`/admin/policies/overview/${policy.uuid}`);
  };

  const onDateChangeConfirm = (rest: boolean) => {
    if (rest) {
      setPeriodDates({
        startDate: null,
        endDate: null,
        key: 'selection',
      });

      setAnalyticsKey('initial');
    } else {
      setAnalyticsKey(
        `${getDateString(periodDates.startDate)}-${getDateString(
          periodDates.endDate,
        )}`,
      );
    }

    setIsAnalyticsModalOpen(false);
  };

  if (isLoading) {
    return <Card textAlign="center">Loading policies...</Card>;
  }

  if (error) {
    return (
      <Card textAlign="center">
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{JSON.stringify(error)}</AlertDescription>
        </Alert>
      </Card>
    );
  }

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
        mb="20px"
      >
        <Flex px="20px" justifyContent="space-between" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            {organization?.is_admin
              ? 'All organization validated policies'
              : 'My validated policies'}
          </Text>
          <Box>
            <Button
              onClick={() => setIsAnalyticsModalOpen(true)}
              variant="outline"
            >
              <CalendarIcon />
              {periodDates.startDate && periodDates.endDate && (
                <Box ml="5px">
                  {getDateRangeLabel(
                    periodDates.startDate,
                    periodDates.endDate,
                  )}
                </Box>
              )}
            </Button>
          </Box>
        </Flex>

        <Table variant="simple" color="gray.500" mt="20px">
          <Thead>
            <Tr>
              {TABLE_HEADERS.map((header) => (
                <Th key={header} pe="10px" borderColor={borderColor}>
                  <Flex
                    justifyContent="space-between"
                    align="center"
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color="gray.400"
                  >
                    {header}
                  </Flex>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {data.results.map((policy, index) => (
              <Tr
                key={policy.uuid}
                onClick={() => onPolicyClick(policy)}
                cursor="pointer"
              >
                <Td>
                  <Flex align="center">
                    <Icon
                      w="24px"
                      h="24px"
                      me="5px"
                      color={
                        {
                          1: 'red.500',
                          2: 'orange.500',
                          3: 'orange.500',
                          4: 'orange.500',
                          5: 'green.500',
                        }?.[policy.submission.status] || null
                      }
                      as={
                        {
                          1: WarningIcon,
                          2: QuestionIcon,
                          3: QuestionIcon,
                          4: CheckCircleIcon,
                          5: CheckCircleIcon,
                        }?.[policy.submission.status] || null
                      }
                    />
                    <Text color={textColor} fontSize="sm" fontWeight="700">
                      {policy.submission.name}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  key={policy.uuid}
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Flex align="center">
                    <Text color={textColor} fontSize="sm" fontWeight="700">
                      {policy.metadata.policy_nr !== 'N/A' && '#'}
                      {policy.metadata.policy_nr}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Flex align="center">
                    <Text color={textColor} fontSize="sm" fontWeight="700">
                      {policy.date}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Flex align="center">
                    <Text color={textColor} fontSize="sm" fontWeight="700">
                      {policy.metadata.broker}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Flex align="center">
                    <Text color={textColor} fontSize="sm" fontWeight="700">
                      {policy.metadata.premium}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Flex align="center">
                    <Text color={textColor} fontSize="sm" fontWeight="700">
                      {policy.metadata.carrier}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Flex align="center">
                    <Text color={textColor} fontSize="sm" fontWeight="700">
                      {policy.score}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Flex align="center">
                    <Progress
                      variant="table"
                      colorScheme="brand"
                      h="8px"
                      w="108px"
                      value={policy.progress}
                    />
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        {data.results.length === 0 && (
          <Box textAlign="center" w="100%" mt="20px">
            No policies found
          </Box>
        )}

        {config.ITEMS_PER_PAGE < data.count && (
          <Paginate
            page={page}
            count={data.count}
            pageSize={config.ITEMS_PER_PAGE}
            onPageChange={(page: number) => setPage(page)}
          />
        )}
      </Card>

      {/* Date range filter modal */}
      <Modal
        isOpen={isAnalyticsModalOpen}
        onClose={() => setIsAnalyticsModalOpen(false)}
        size="5xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter policies by date</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box textAlign="center">
              <DateRangePicker
                onChange={(item: any) => setPeriodDates(item.selection)}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={[periodDates]}
                direction="horizontal"
                firstDayOfWeek={1}
              />
            </Box>
            <Box mb="15px">
              <Button
                onClick={() => onDateChangeConfirm(false)}
                variant="brand"
              >
                Apply changes
              </Button>
              <Button
                variant="outline"
                ml="20px"
                onClick={() => onDateChangeConfirm(true)}
              >
                Reset
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ValidatedPoliciesView;
