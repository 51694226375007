import { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from 'hooks';

import { Text, SimpleGrid } from '@chakra-ui/react';

import Card from 'components/card/Card';

import FormInput from '../../components/FormInput';
import DateInput from '../../components/DateInput';
import SelectInput from '../../components/SelectInput';

import getDateFromString from '../../helpers/getDateFromString';

import { changeMethodOfPayment } from 'reducers/submissionFormReducer';

import { checkDatesMatch } from 'views/submission/sections/MethodOfPayment/helpers';

import getDateString from 'helpers/getDateString';

import { SECTIONS } from 'views/submission/constants';

const PAYMENT_PLAN_OPTIONS = [
  { value: 'Monthly', label: 'Monthly' },
  { value: 'Quarterly', label: 'Quarterly' },
  { value: 'Annually', label: 'Annually' },
];

const MethodOfPayment = () => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(
    (state) => state.submissionForm.data.method_of_payment,
  );
  const policyPeriod = useAppSelector(
    (state) => state.submissionForm.data.policy_period,
  );

  const onMethodOfPaymentChange = (event: any) => {
    const { name, value } = event.target;

    dispatch(changeMethodOfPayment({ name, value }));
  };

  const onDateChange = (date: Date, name: string) => {
    dispatch(changeMethodOfPayment({ name, value: getDateString(date) }));
  };

  useEffect(() => {
    const sum =
      parseFloat(data.estimated_policy_premium) +
      parseFloat(data.tax) +
      parseFloat(data.interest);

    dispatch(
      changeMethodOfPayment({
        name: 'total_estimated_cost',
        value: sum.toString(),
      }),
    );
  }, [dispatch, data.estimated_policy_premium, data.tax, data.interest]);

  useEffect(() => {
    const sum =
      parseFloat(data.total_estimated_cost) -
      parseFloat(data.amt_paid_with_application);

    dispatch(
      changeMethodOfPayment({
        name: 'amount_still_due',
        value: sum.toString(),
      }),
    );
  }, [dispatch, data.total_estimated_cost, data.amt_paid_with_application]);

  useEffect(() => {
    const instalments = {
      Monthly: '12',
      Quarterly: '4',
      Annually: '1',
    } as { [key: string]: string };

    // If the effective date and expiry date month and day don't match, assume it's a 6-month policy
    if (
      !checkDatesMatch(policyPeriod.effective_date, policyPeriod.expiry_date)
    ) {
      instalments['Monthly'] = '6';
      instalments['Quarterly'] = '2';
    }

    dispatch(
      changeMethodOfPayment({
        name: 'no_of_remaining_instalments',
        value: instalments?.[data.type_of_payment_plan],
      }),
    );
  }, [dispatch, data.type_of_payment_plan, policyPeriod]);

  useEffect(() => {
    const sum = (
      parseFloat(data.amount_still_due) /
      parseFloat(data.no_of_remaining_instalments)
    ).toFixed(2);

    dispatch(
      changeMethodOfPayment({
        name: 'amount_of_each_instalment',
        value: sum.toString(),
      }),
    );
  }, [dispatch, data.amount_still_due, data.no_of_remaining_instalments]);

  return (
    <Card mb="20px" id="sectionMethodOfPayment">
      <Text fontWeight="bold" fontSize="2xl" mb="20px">
        Method of Payment
      </Text>

      <SimpleGrid columns={5} spacing="20px">
        <SelectInput
          section={SECTIONS.METHOD_OF_PAYMENT}
          label="Type of Payment Plan"
          name="type_of_payment_plan"
          value={data.type_of_payment_plan}
          options={PAYMENT_PLAN_OPTIONS}
          onChange={onMethodOfPaymentChange}
        />

        <FormInput
          type="number"
          section={SECTIONS.METHOD_OF_PAYMENT}
          label="Estimated Policy Premium ** "
          name="estimated_policy_premium"
          value={data.estimated_policy_premium}
          onChange={onMethodOfPaymentChange}
          required
        />

        <FormInput
          type="number"
          section={SECTIONS.METHOD_OF_PAYMENT}
          label="Tax"
          name="tax"
          value={data.tax}
          onChange={onMethodOfPaymentChange}
          required
        />

        <FormInput
          type="number"
          section={SECTIONS.METHOD_OF_PAYMENT}
          label="Interest"
          name="interest"
          value={data.interest}
          onChange={onMethodOfPaymentChange}
          required
        />

        <FormInput
          type="number"
          section={SECTIONS.METHOD_OF_PAYMENT}
          label="Total Estimated Cost"
          name="total_estimated_cost"
          value={data.total_estimated_cost}
          onChange={onMethodOfPaymentChange}
          required
        />

        <FormInput
          type="number"
          section={SECTIONS.METHOD_OF_PAYMENT}
          label="Amt. Paid with Application"
          name="amt_paid_with_application"
          value={data.amt_paid_with_application}
          onChange={onMethodOfPaymentChange}
          required
        />

        <FormInput
          type="number"
          section={SECTIONS.METHOD_OF_PAYMENT}
          label="Amount Still Due"
          name="amount_still_due"
          value={data.amount_still_due}
          onChange={onMethodOfPaymentChange}
          required
        />

        <FormInput
          type="number"
          section={SECTIONS.METHOD_OF_PAYMENT}
          label="No. of Remaining Instalments"
          name="no_of_remaining_instalments"
          value={data.no_of_remaining_instalments}
          onChange={onMethodOfPaymentChange}
          required
        />

        <FormInput
          type="number"
          section={SECTIONS.METHOD_OF_PAYMENT}
          label="Amount of Each Instalment"
          name="amount_of_each_instalment"
          value={data.amount_of_each_instalment}
          onChange={onMethodOfPaymentChange}
          required
        />

        <DateInput
          section={SECTIONS.METHOD_OF_PAYMENT}
          label="Instalment Due Date"
          date={getDateFromString(data.instalment_due_date)}
          onChange={(date: Date) => onDateChange(date, 'instalment_due_date')}
          required
        />
      </SimpleGrid>
    </Card>
  );
};

export default MethodOfPayment;
