import { useState } from 'react';

import {
  Box,
  Flex,
  Text,
  FormControl,
  useColorModeValue,
  Input,
  InputGroup,
  Button,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Image,
} from '@chakra-ui/react';

import ValidatedPolicy from 'views/_mypolicy/ValidatedPolicy';

import config from 'config';

import { isLocal } from 'utils/helpers';

import logo from 'assets/img/others/ppgrey.png';

const PublicValidationsView = () => {
  const [token, setToken] = useState('');
  const [showValidations, setShowValidations] = useState(false);
  const [error, setError] = useState('');

  const textColor = useColorModeValue('navy.700', 'white');

  const handleOnSubmit = () => {
    if (token.length !== 36) {
      setError('Invalid key');
      return;
    }

    setShowValidations(true);
  };

  if (!showValidations) {
    return (
      <Center>
        <Flex
          maxW={{ base: '100%', md: 'max-content' }}
          w="100%"
          mx={{ base: 'auto', lg: '0px' }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: '30px', md: '60px' }}
          px={{ base: '25px', md: '0px' }}
          mt={{ base: '40px', md: '14vh' }}
          flexDirection="column"
        >
          <Box me="auto">
            <Text
              mb="20px"
              ms="4px"
              color={textColor}
              fontWeight="400"
              fontSize="md"
            >
              Enter key to view validations
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: '100%', md: '420px' }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: 'auto', lg: 'unset' }}
            me="auto"
            mb={{ base: '20px', md: 'auto' }}
          >
            <FormControl>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Your key"
                  mb="24px"
                  size="lg"
                  type="password"
                  variant="auth"
                  onChange={(e) => setToken(e.target.value)}
                />
              </InputGroup>
              <Button
                fontSize="sm"
                variant="brand"
                _hover={{ bg: 'brand.300' }}
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                onClick={handleOnSubmit}
              >
                View
              </Button>

              {error && (
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
            </FormControl>
          </Flex>
        </Flex>
      </Center>
    );
  }

  return (
    <Box px="20px" pt="20px">
      <Image src={logo} alt="policypilot logo" width="270px" />
      <ValidatedPolicy
        custom_auth={{
          token: token,
          endpoint: isLocal()
            ? config.ORGANISATIONS.local.api_endpoint
            : config.ORGANISATIONS.development.api_endpoint,
        }}
      />
    </Box>
  );
};

export default PublicValidationsView;
