import { useEffect, useContext } from 'react';

import {
  FormLabel,
  Input,
  Text,
  useColorModeValue,
  FormHelperText,
  FormControl,
} from '@chakra-ui/react';

import { useAppDispatch, useAppSelector } from 'hooks';

import {
  addMissingMandatoryField,
  removeMissingMandatoryField,
} from 'reducers/submissionFormReducer';

import Message from '../Message';

import { MessageProps } from '../Message/Message.types';

import { AuthContext } from 'contexts/AuthContext';

import isSubmissionDisabled from 'views/submission/helpers/isSubmissionDisabled';

const FormInput = ({
  section,
  label,
  extra,
  placeholder,
  type,
  helpText,
  value,
  onChange,
  message,
  required,
  subLabel,
  overrideDisabled,
  ...rest
}: {
  section: number;
  label?: string;
  extra?: JSX.Element | string;
  placeholder?: string;
  type?: string;
  helpText?: string;
  value?: string;
  onChange?: (event: any) => void;
  message?: Omit<MessageProps, 'section'> & { section?: number };
  required?: boolean;
  subLabel?: string;

  [x: string]: any;
}) => {
  const { organization } = useContext(AuthContext);
  const dispatch = useAppDispatch();

  const submissionStatus = useAppSelector(
    (state) => state.submissionForm.status,
  );

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const htmlID = subLabel ? `${label} (${subLabel})` : label;
  const isDisabled = overrideDisabled
    ? false
    : isSubmissionDisabled(submissionStatus, organization.role);

  useEffect(() => {
    if (!required) return;

    if (value.length === 0) {
      dispatch(addMissingMandatoryField({ name: htmlID, section }));
    } else {
      dispatch(removeMissingMandatoryField(htmlID));
    }
  }, [required, value, htmlID, dispatch]);

  return (
    <FormControl>
      <FormLabel
        display="flex"
        ms="10px"
        htmlFor={htmlID}
        fontSize="sm"
        color={textColorPrimary}
        fontWeight="bold"
        _hover={{ cursor: 'pointer' }}
      >
        {label}
        <Text fontSize="sm" fontWeight="400" ms="2px">
          {extra}
        </Text>
      </FormLabel>

      <Input
        type={type}
        id={htmlID}
        fontWeight="500"
        variant="main"
        placeholder={placeholder}
        _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
        h="44px"
        maxH="44px"
        borderColor={!value && required ? 'red' : 'inherit'}
        value={value}
        onChange={(e) => onChange(e)}
        disabled={isDisabled}
        backgroundColor={isDisabled ? 'gray.200' : 'white'}
        title={isDisabled ? 'Editing is disabled' : ''}
        {...rest}
      />

      {helpText && <FormHelperText ml="10px">{helpText}</FormHelperText>}

      {message && <Message {...message} section={section} />}
    </FormControl>
  );
};

export default FormInput;
