import { useEffect, useContext } from 'react';

import { FormControl } from '@chakra-ui/react';

import DateField from 'components/fields/DateField';

import Message from '../Message';

import { AuthContext } from 'contexts/AuthContext';

import { useAppDispatch, useAppSelector } from 'hooks';

import isSubmissionDisabled from 'views/submission/helpers/isSubmissionDisabled';

import {
  addMissingMandatoryField,
  removeMissingMandatoryField,
} from 'reducers/submissionFormReducer';

import { MessageProps } from '../Message/Message.types';

const DateInput = ({
  label,
  date,
  onChange,
  section,
  required,
  message,
  subLabel,
}: {
  label: string;
  date: Date;
  onChange: any;
  section: number;
  required?: boolean;
  message?: Omit<MessageProps, 'section'> & { section?: number };
  subLabel?: string;
}) => {
  const { organization } = useContext(AuthContext);
  const dispatch = useAppDispatch();

  const submissionStatus = useAppSelector(
    (state) => state.submissionForm.status,
  );

  const htmlID = subLabel ? `${label} (${subLabel})` : label;
  const isDisabled = isSubmissionDisabled(submissionStatus, organization.role);

  useEffect(() => {
    if (!required) return;

    if (date === null) {
      dispatch(addMissingMandatoryField({ name: htmlID, section }));
    } else {
      dispatch(removeMissingMandatoryField(htmlID));
    }
  }, [required, date, htmlID, dispatch]);

  return (
    <FormControl>
      <DateField
        label={label}
        date={date}
        onChange={(e: Date) => onChange(e)}
        required={required}
        htmlID={htmlID}
        isDisabled={isDisabled}
      />

      {message && <Message {...message} section={section} />}
    </FormControl>
  );
};

export default DateInput;
