import { useAppSelector, useAppDispatch } from 'hooks';

import { Text, SimpleGrid } from '@chakra-ui/react';

import Card from 'components/card/Card';

import FormInput from '../../components/FormInput';

import { changeCompanyAndBrokerInformation } from 'reducers/submissionFormReducer';

import { SECTIONS } from 'views/submission/constants';

const InsuranceCompanyInformation = () => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(
    (state) => state.submissionForm.data.company_and_broker_information,
  );

  const onCompanyAndBrokerInformationChange = (event: any) => {
    const { name, value } = event.target;

    dispatch(changeCompanyAndBrokerInformation({ name, value }));
  };

  return (
    <Card mb="20px" id="sectionInsuranceCompanyInformation">
      <Text fontWeight="bold" fontSize="2xl" mb="20px">
        Insurance Company and Broker/Agent Information
      </Text>

      <SimpleGrid columns={3} spacing="20px">
        <FormInput
          section={SECTIONS.INSURANCE_COMPANY_INFORMATION}
          label="Insurance Company (Insurer)"
          name="insurance_company"
          value={data.insurance_company}
          onChange={onCompanyAndBrokerInformationChange}
          required
        />
        <FormInput
          section={SECTIONS.INSURANCE_COMPANY_INFORMATION}
          label="Broker/Agent"
          name="broker"
          value={data.broker}
          onChange={onCompanyAndBrokerInformationChange}
          required
        />
        <FormInput
          section={SECTIONS.INSURANCE_COMPANY_INFORMATION}
          label="Broker Code"
          name="broker_code"
          value={data.broker_code}
          onChange={onCompanyAndBrokerInformationChange}
          required
        />
      </SimpleGrid>
    </Card>
  );
};

export default InsuranceCompanyInformation;
