import Storage from 'storage';

export const isLocal = () => {
  return window.location.hostname === 'localhost';
};

export const isDevAccount = () => {
  if (isLocal()) return true;

  const organization = Storage.get('organization');
  const user = Storage.get('user');

  if (!user || !organization) return false;

  if (organization.name !== 'MyChoice') return false;

  return user.username === 'admin';
};
