import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';

import { AuthContext } from 'contexts/AuthContext';

import { apiAuthenticate } from 'api';

import config from 'config';

import Storage from 'storage';

import Background from './components/Background';

const SignIn = () => {
  const navigate = useNavigate();
  const { authDispatch } = useContext(AuthContext);

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAutoDetectedOrganisation, setIsAutoDetectedOrganisation] =
    useState(false);

  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  const handleSignIn = () => {
    if (!Object.keys(config.ORGANISATIONS).includes(organisation)) {
      setError('Invalid organisation');
      return;
    }

    Storage.store(
      'API_ENDPOINT',
      // @ts-ignore
      config.ORGANISATIONS[organisation].api_endpoint,
    );

    setIsLoading(true);

    apiAuthenticate({ email, password })
      .then((response) => {
        authDispatch({ type: 'set_user', payload: response });
        navigate('/');
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const currentHost = window.location.hostname;

    for (const [organisation_key, organisation] of Object.entries(
      config.ORGANISATIONS,
    )) {
      if (currentHost === organisation.auto_detect_host) {
        setIsAutoDetectedOrganisation(true);
        setOrganisation(organisation_key);
        break;
      }
    }
  }, []);

  return (
    <Background>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            {!isAutoDetectedOrganisation
              ? 'Sign In'
              : // @ts-ignore
                `Sign In - ${config.ORGANISATIONS[organisation].name}`}
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSignIn();
            }}
          >
            <FormControl>
              {!isAutoDetectedOrganisation && (
                <>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Organization<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    type="text"
                    placeholder=""
                    mb="24px"
                    fontWeight="500"
                    size="lg"
                    value={organisation}
                    onChange={(e) => setOrganisation(e.target.value)}
                  />
                </>
              )}

              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email or Username<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                type="text"
                placeholder="mail@example.com"
                mb="24px"
                fontWeight="500"
                size="lg"
                onChange={(e) => setEmail(e.target.value)}
              />
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Your password"
                  mb="24px"
                  size="lg"
                  type={showPassword ? 'text' : 'password'}
                  variant="auth"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: 'pointer' }}
                    as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </InputRightElement>
              </InputGroup>
              <Button
                type="submit"
                fontSize="sm"
                variant="brand"
                _hover={{ bg: 'brand.300' }}
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                onClick={handleSignIn}
                isLoading={isLoading}
              >
                Sign In
              </Button>

              {error && (
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
            </FormControl>
          </form>
        </Flex>
      </Flex>
    </Background>
  );
};

export default SignIn;
