import { useContext } from 'react';

import { useParams, useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import {
  Box,
  Flex,
  Icon,
  Progress,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  StackDivider,
  Heading,
  Center,
  OrderedList,
  ListItem,
  Link,
  Button,
  Grid,
  GridItem,
  Tooltip,
} from '@chakra-ui/react';

import {
  ExternalLinkIcon,
  QuestionIcon,
  WarningIcon,
  CheckCircleIcon,
} from '@chakra-ui/icons';

import Card from 'components/card/Card';

import { AuthContext } from 'contexts/AuthContext';

import { apiPolicyOverview, apiUpdateSubmission } from 'api';

import { setTokenCookie } from 'utils/cookies';

import type { APIPolicyOverview } from 'types/api.types';

import {
  SUBMISSION_STATUS_FAILED,
  SUBMISSION_STATUS_APPROVED,
  SUBMISSION_STATUS_COMPLETED,
  SUBMISSION_STATUS_IN_PROGRESS,
  SUBMISSION_STATUS_IN_REVIEW,
  ROLE_BROKER,
  ROLE_ADMIN,
} from 'variables/constants';

const PolicyOverviewView = () => {
  const { organization, user } = useContext(AuthContext);
  const { uuid } = useParams();
  const navigate = useNavigate();

  const { error, data, isLoading, refetch } = useQuery({
    queryKey: ['policy-overview', uuid],
    queryFn: () => apiPolicyOverview(uuid),
  }) as {
    data: APIPolicyOverview;
    isLoading: boolean;
    error: any;
    refetch: any;
  };

  const onPDFLinkClick = (url: string) => {
    setTokenCookie(organization.token, user.token);
    window.open(url, '_blank');
  };

  const getApprovalErrorMessage = () => {
    if (
      [SUBMISSION_STATUS_APPROVED, SUBMISSION_STATUS_COMPLETED].includes(
        data.submission.status,
      )
    ) {
      return null;
    }

    if (data.submission.status === SUBMISSION_STATUS_FAILED) {
      return 'Policy submission failed. Please review the policy and resubmit.';
    }

    if (!data.is_form_saved) {
      return 'Form data has not been saved';
    }

    if (data.submission.status === SUBMISSION_STATUS_IN_PROGRESS) {
      return 'Submission is being prepared';
    }

    if (data.has_missing_data) {
      return 'All mandatory fields have to be filled, remarks added, and required files uploaded to approve the submission';
    }

    return null;
  };

  const disableApprove = () => {
    if (!data.is_form_saved) return true;

    if (
      organization.role === ROLE_ADMIN &&
      ![SUBMISSION_STATUS_APPROVED, SUBMISSION_STATUS_COMPLETED].includes(
        data.submission.status,
      )
    )
      return false;

    if (data.submission.status === SUBMISSION_STATUS_FAILED) return true;
    if (data.submission.status !== SUBMISSION_STATUS_IN_REVIEW) return true;
    if (data.has_missing_data) return true;

    return false;
  };

  const getApproveCTAText = () => {
    if (
      [SUBMISSION_STATUS_APPROVED, SUBMISSION_STATUS_COMPLETED].includes(
        data.submission.status,
      )
    ) {
      return 'Approved';
    }

    return 'Approve';
  };

  const onSubmissionApprove = () => {
    apiUpdateSubmission(uuid, { status: SUBMISSION_STATUS_APPROVED }).then(
      (response: any) => {
        refetch();
      },
    );
  };

  const getTooltipText = () => {
    if (organization.role === ROLE_ADMIN) return 'Admin override enabled';

    return '';
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  if (isLoading) {
    return <Card textAlign="center">Loading policy...</Card>;
  }

  if (error) {
    return (
      <Card textAlign="center">
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{JSON.stringify(error)}</AlertDescription>
        </Alert>
      </Card>
    );
  }

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex px="25px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Policy Overview
        </Text>
      </Flex>

      <Grid templateColumns="repeat(12, 1fr)" gap="20px" mt="20px">
        <GridItem colSpan={4}>
          <Stack divider={<StackDivider />} spacing="4" px="25px">
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Policy No.:
              </Heading>
              <Text pt="2" fontSize="sm">
                {data.metadata.policy_nr}
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Status:
              </Heading>
              <Text pt="2" fontSize="sm">
                <Icon
                  w="24px"
                  h="24px"
                  me="5px"
                  color={
                    {
                      1: 'red.500',
                      2: 'orange.500',
                      3: 'orange.500',
                      4: 'orange.500',
                      5: 'green.500',
                    }?.[data.submission.status] || null
                  }
                  as={
                    {
                      1: WarningIcon,
                      2: QuestionIcon,
                      3: QuestionIcon,
                      4: CheckCircleIcon,
                      5: CheckCircleIcon,
                    }?.[data.submission.status] || null
                  }
                />
                {data.submission.name}
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Date:
              </Heading>
              <Text pt="2" fontSize="sm">
                {data.date}
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Broker name:
              </Heading>
              <Text pt="2" fontSize="sm">
                {data.metadata.broker}
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Premium:
              </Heading>
              <Text pt="2" fontSize="sm">
                {data.metadata.premium}
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Carrier:
              </Heading>
              <Text pt="2" fontSize="sm">
                {data.metadata.carrier}
              </Text>
            </Box>
          </Stack>
        </GridItem>

        <GridItem colSpan={4}>
          <Box>
            <Stack divider={<StackDivider />} spacing="4">
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  Underwriting score:
                </Heading>
                <Text pt="2" fontSize="sm">
                  <Center
                    w="40px"
                    h="40px"
                    bg="brand.400"
                    color="white"
                    borderRadius={5}
                  >
                    <Box as="span" fontWeight="bold" fontSize="lg">
                      {data.score}
                    </Box>
                  </Center>
                </Text>
              </Box>
            </Stack>

            <Stack divider={<StackDivider />} spacing="4" pr="25px" mt="30px">
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  Validations:
                </Heading>
                <Text pt="2" fontSize="sm">
                  <Link
                    color="teal.500"
                    onClick={() =>
                      navigate(`/admin/policies/validations/${uuid}`)
                    }
                  >
                    View policy validations
                  </Link>
                </Text>
              </Box>
            </Stack>

            <Stack divider={<StackDivider />} spacing="4" pr="25px" mt="30px">
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  Application submission form:
                </Heading>
                <Text pt="2" fontSize="sm">
                  {data.metadata.policy_nr === 'N/A' && '-'}
                  {data.metadata.policy_nr !== 'N/A' && (
                    <Link
                      color="teal.500"
                      onClick={() =>
                        navigate(`/admin/policies/submission/${uuid}`)
                      }
                    >
                      View application submission form
                    </Link>
                  )}
                </Text>
              </Box>
            </Stack>

            <Box mt="30px">
              <Stack divider={<StackDivider />} spacing="4" pr="25px">
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    Attached files:
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {data.application_files.length === 0
                      ? 'No files attached'
                      : ''}

                    <OrderedList lineHeight="30px">
                      {data.application_files.map((file) => (
                        <ListItem key={file.name}>
                          <strong>{file.type}:</strong>{' '}
                          <Link href={file.file} isExternal color="teal.500">
                            {file.name} <ExternalLinkIcon mx="2px" />
                          </Link>
                        </ListItem>
                      ))}
                    </OrderedList>
                  </Text>
                </Box>
              </Stack>
            </Box>

            <Box mt="30px">
              <Stack divider={<StackDivider />} spacing="4" pr="25px">
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    Generated Application PDFs:
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {data.application_pdfs.length === 0 &&
                      'No PDF generated yet'}

                    <OrderedList lineHeight="30px">
                      {(
                        data.application_pdfs as APIPolicyOverview['application_pdfs']
                      ).map((pdf) => (
                        <ListItem key={pdf.created_at}>
                          <Link
                            isExternal
                            color="teal.500"
                            onClick={() => onPDFLinkClick(pdf.url)}
                          >
                            {pdf.status} - {pdf.created_at}
                            <ExternalLinkIcon mx="2px" />
                          </Link>
                        </ListItem>
                      ))}
                    </OrderedList>
                  </Text>
                </Box>
              </Stack>
            </Box>

            <Box mt="30px">
              <Stack divider={<StackDivider />} spacing="4" pr="25px">
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    Generated Liability slip PDFs:
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {data.liability_slip_pdfs.length === 0 &&
                      'No PDF generated yet'}

                    <OrderedList lineHeight="30px">
                      {(
                        data.liability_slip_pdfs as APIPolicyOverview['liability_slip_pdfs']
                      ).map((slips) => (
                        <ListItem key={slips.created_at}>
                          Version - {slips.created_at} (Days - {slips.days})
                          {slips.urls.map((url, index) => (
                            <Link
                              isExternal
                              color="teal.500"
                              onClick={() => onPDFLinkClick(url)}
                              key={index}
                              display="block"
                            >
                              Vehicle ({index + 1}) Liability Slip
                              <ExternalLinkIcon mx="2px" />
                            </Link>
                          ))}
                        </ListItem>
                      ))}
                    </OrderedList>
                  </Text>
                </Box>
              </Stack>
            </Box>
          </Box>
        </GridItem>

        <GridItem colSpan={4}>
          <Box>
            <Stack divider={<StackDivider />} spacing="4" px="25px">
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  Progress: {data.progress}%
                </Heading>
                <Text pt="2" fontSize="sm">
                  <Progress
                    variant="table"
                    colorScheme="brand"
                    h="8px"
                    w="100%"
                    mt="20px"
                    value={data.progress}
                  />
                </Text>
              </Box>
            </Stack>

            {organization.role !== ROLE_BROKER && (
              <Stack divider={<StackDivider />} spacing="4" px="25px" mt="30px">
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    Approve:
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    <Tooltip placement="top" label={getTooltipText()}>
                      <Button
                        w="100%"
                        variant="green"
                        disabled={disableApprove()}
                        onClick={onSubmissionApprove}
                      >
                        {getApproveCTAText()}
                      </Button>
                    </Tooltip>
                  </Text>

                  {getApprovalErrorMessage() && (
                    <Alert
                      status={
                        data.submission.status === SUBMISSION_STATUS_FAILED
                          ? 'error'
                          : 'info'
                      }
                      mt="10px"
                    >
                      <AlertIcon />
                      <AlertDescription>
                        {getApprovalErrorMessage()}
                      </AlertDescription>
                    </Alert>
                  )}
                </Box>
              </Stack>
            )}

            {organization.role !== ROLE_BROKER && (
              <Stack divider={<StackDivider />} spacing="4" px="25px" mt="30px">
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    Underwritten by:
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {data.underwriter || '...'}
                  </Text>
                </Box>
              </Stack>
            )}
          </Box>
        </GridItem>
      </Grid>
    </Card>
  );
};

export default PolicyOverviewView;
