import { Box } from '@chakra-ui/react';

import { splitValidationText } from 'utils/utils';

const ValidationMessage = ({
  message,
  boldFirst = true,
}: {
  message: string;
  boldFirst?: boolean;
}) => {
  const messagesParts = splitValidationText(message);

  return (
    <Box>
      {messagesParts.map((msg: string, index: number) => (
        <Box
          key={index}
          mb={index !== messagesParts.length - 1 ? '10px' : ''}
          fontWeight={
            boldFirst && index === 0 && messagesParts.length !== 1 ? 700 : 400
          }
        >
          {msg}
        </Box>
      ))}
    </Box>
  );
};

export default ValidationMessage;
