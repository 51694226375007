import { useAppSelector, useAppDispatch } from 'hooks';

import { Text, SimpleGrid, Flex, Box } from '@chakra-ui/react';

import Card from 'components/card/Card';

import FormInput from '../../components/FormInput';

import { FILE_TYPE, SECTIONS } from 'views/submission/constants';

import { changeApplicant } from 'reducers/submissionFormReducer';

const Applicant = () => {
  const dispatch = useAppDispatch();

  const applicant = useAppSelector(
    (state) => state.submissionForm.data.applicant,
  );

  const onApplicantChange = (event: any) => {
    const { name, value } = event.target;

    dispatch(changeApplicant({ name, value }));
  };

  return (
    <Card mb="20px" id="sectionApplicant">
      <Text fontWeight="bold" fontSize="2xl" mb="20px">
        Applicant's Name & Primary Address
      </Text>

      <SimpleGrid columns={5} spacing="20px">
        <FormInput
          section={SECTIONS.APPLICANT}
          label="Name"
          name="name"
          value={applicant.name}
          onChange={onApplicantChange}
          required
        />
        <FormInput
          section={SECTIONS.APPLICANT}
          label="Address"
          name="address"
          value={applicant.address}
          onChange={onApplicantChange}
          message={{
            validationId: '008',
            file: FILE_TYPE.TYPE_PROOF_OF_RESIDENCY,
            compact: true,
          }}
          required
        />
        <FormInput
          section={SECTIONS.APPLICANT}
          label="City"
          name="city"
          value={applicant.city}
          onChange={onApplicantChange}
        />
        <FormInput
          section={SECTIONS.APPLICANT}
          label="Province code"
          name="province_code"
          value={applicant.province_code}
          onChange={onApplicantChange}
        />
        <FormInput
          section={SECTIONS.APPLICANT}
          label="Postal code"
          name="postal_code"
          value={applicant.postal_code}
          onChange={onApplicantChange}
          required
        />
      </SimpleGrid>

      <Flex gap="20px" mt="20px">
        <Box w="20%">
          <FormInput
            section={SECTIONS.APPLICANT}
            textAlign="center"
            label="Phone No. Home (prefix)"
            name="phone_home_prefix"
            value={applicant.phone_home_prefix}
            onChange={onApplicantChange}
            required
          />
        </Box>
        <Box w="30%">
          <FormInput
            section={SECTIONS.APPLICANT}
            label="Phone No. Home"
            name="phone_home"
            value={applicant.phone_home}
            onChange={onApplicantChange}
            required
          />
        </Box>
        <Box w="20%">
          <FormInput
            section={SECTIONS.APPLICANT}
            textAlign="center"
            label="Phone No. Work (prefix)"
            name="phone_work_prefix"
            value={applicant.phone_work_prefix}
            onChange={onApplicantChange}
          />
        </Box>
        <Box w="30%">
          <FormInput
            section={SECTIONS.APPLICANT}
            label="Phone No. Work"
            name="phone_work"
            value={applicant.phone_work}
            onChange={onApplicantChange}
          />
        </Box>
      </Flex>
    </Card>
  );
};

export default Applicant;
