import { useState, useReducer } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { ChakraProvider } from '@chakra-ui/react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AuthContext } from 'contexts/AuthContext';

import authReducer from 'reducers/authReducer';

import Storage from 'storage';

import AdminLayout from './layouts/admin';

import initialTheme from './theme/theme';

import SignIn from 'views/signin';

import PublicValidationsView from 'views/public_validations/PublicValidationsView';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import './assets/css/App.css';

const queryClient = new QueryClient();

const Main = () => {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  const [{ user, organization }, authDispatch] = useReducer(authReducer, {
    user: Storage.get('user'),
    organization: Storage.get('organization'),
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={currentTheme}>
        <AuthContext.Provider value={{ user, organization, authDispatch }}>
          <Routes>
            <Route
              path="/validations/:uuid"
              element={<PublicValidationsView />}
            />
            {user ? (
              <>
                <Route
                  path="admin/*"
                  element={
                    <AdminLayout
                      theme={currentTheme}
                      setTheme={setCurrentTheme}
                    />
                  }
                />
                <Route path="*" element={<Navigate to="/admin" />} />
              </>
            ) : (
              <>
                <Route path="/auth/sign-in" element={<SignIn />} />
                <Route path="*" element={<Navigate to="/auth/sign-in" />} />
              </>
            )}
          </Routes>
        </AuthContext.Provider>
      </ChakraProvider>
    </QueryClientProvider>
  );
};

export default Main;
