import { useContext } from 'react';

import { useParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from 'hooks';

import { AuthContext } from 'contexts/AuthContext';

import Card from 'components/card/Card';

import { ExternalLinkIcon } from '@chakra-ui/icons';

import {
  Box,
  Link,
  Text,
  Button,
  Grid,
  GridItem,
  ListItem,
  OrderedList,
} from '@chakra-ui/react';

import FormInput from '../../components/FormInput';

import {
  changeLiabilitySlipDays,
  changeLiabilitySlip,
} from 'reducers/submissionFormReducer';

import { setTokenCookie } from 'utils/cookies';

import { apiCreateLiabilitySlip } from 'api';

import {
  ROLE_ADMIN,
  SUBMISSION_STATUS_APPROVED,
  SUBMISSION_STATUS_COMPLETED,
} from 'variables/constants';

import { SECTIONS } from 'views/submission/constants';

const LiabilitySlip = () => {
  const dispatch = useAppDispatch();
  const { organization, user } = useContext(AuthContext);
  const { uuid } = useParams();

  const liability_slip = useAppSelector(
    (state) => state.submissionForm.liability_slip,
  );

  const submissionStatus = useAppSelector(
    (state) => state.submissionForm.status,
  );

  const onDaysChange = (event: any) => {
    let value = event.target.value;

    if (value < 1) value = 1;
    if (value > 30) value = 30;

    dispatch(changeLiabilitySlipDays(value));
  };

  const onGenerateLiabilitySlip = () => {
    apiCreateLiabilitySlip(uuid, liability_slip.days).then((response: any) => {
      dispatch(changeLiabilitySlip(response));
    });
  };

  const onUrlOpen = (url: string) => {
    setTokenCookie(organization.token, user.token);
    window.open(url, '_blank');
  };

  const showInputs = () => {
    if (organization.role === ROLE_ADMIN) return true;

    return liability_slip.urls.length === 0;
  };

  if (
    ![SUBMISSION_STATUS_APPROVED, SUBMISSION_STATUS_COMPLETED].includes(
      submissionStatus,
    )
  ) {
    return null;
  }

  return (
    <Box w="100%">
      <Card mb="20px">
        <Text fontWeight="bold" fontSize="2xl">
          Liability Slip/s
        </Text>

        {showInputs() && (
          <Grid templateColumns="repeat(6, 1fr)" gap="20px" mt="20px" mb="10px">
            <GridItem colSpan={2}>
              <FormInput
                section={SECTIONS.LIABILITY_SLIP}
                label="Days"
                value={liability_slip.days}
                onChange={onDaysChange}
                helpText="Between 1 and 30"
                overrideDisabled
              />
            </GridItem>

            <GridItem colSpan={4} pt="30px">
              <Button
                onClick={() => onGenerateLiabilitySlip()}
                variant="brand"
                w="100%"
                disabled={liability_slip.days.length === 0}
              >
                Generate PDF/s
              </Button>
            </GridItem>
          </Grid>
        )}

        <OrderedList>
          {liability_slip.urls.map((url, index) => {
            return (
              <ListItem key={index}>
                <Link
                  color="teal.500"
                  onClick={() => onUrlOpen(url)}
                  key={index}
                  textDecoration="underline"
                >
                  Vehicle ({index + 1}) Liability Slip (
                  {liability_slip.created_at})
                  <ExternalLinkIcon mx="2px" />
                </Link>
              </ListItem>
            );
          })}
        </OrderedList>
      </Card>
    </Box>
  );
};

export default LiabilitySlip;
