import { Box } from '@chakra-ui/react';

const Divider = ({
  children,
  ...rest
}: {
  children: any;
  [x: string]: any;
}) => {
  return (
    <Box
      rounded="5px"
      p="5px"
      pl="10px"
      backgroundColor="brand.400"
      color="white"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Divider;
