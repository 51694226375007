import { useContext, useEffect } from 'react';

import { useAppSelector, useAppDispatch } from 'hooks';

import {
  Alert,
  AlertIcon,
  Box,
  AlertDescription,
  Flex,
} from '@chakra-ui/react';

import ValidationMessage from 'components/validation/ValidationMessage';

import TextFieldInput from '../../components/TextFieldInput';
import FormInput from '../../components/FormInput';
import DateInput from '../../components/DateInput';
import CheckBoxInput from '../../components/CheckBoxInput';

import Divider from '../../components/Divider';

import getDateFromString from '../../helpers/getDateFromString';

import { ApplicationFormContext } from 'contexts/ApplicationFormContext';

import getDateString from 'helpers/getDateString';

import {
  addUnlistedSpouse,
  changeUnlistedSpouse,
  changeRemarks,
} from 'reducers/submissionFormReducer';

import { SECTIONS } from 'views/submission/constants';

const UnlistedSpouses = () => {
  const { validations } = useContext(ApplicationFormContext) as {
    validations: Validation[];
  };
  const dispatch = useAppDispatch();

  const spouses = useAppSelector(
    (state) => state.submissionForm.data.unlisted_spouses,
  );

  const validation = validations.find((v) => v.validation_id === '022');

  useEffect(() => {
    // Create initial state only for the first time data is loaded
    if (!validation || spouses.length > 0) return;

    for (let i = 0; i < validation.failed_objects.length; i++) {
      dispatch(addUnlistedSpouse({ is_in_household: false }));
    }
  }, [validation, spouses, dispatch]);

  useEffect(() => {
    spouses.forEach((spouse, index) => {
      if (!spouse.is_in_household) {
        dispatch(
          changeRemarks({
            name: 'remark-022',
            value: `Spouse: ${
              spouse.name || '???'
            } - Is this spouse still in the household?: No - Reason not in household: ${
              spouse.no_reason || '???'
            }`,
            key: `spouse-${index + 1}`,
          }),
        );
      } else {
        dispatch(
          changeRemarks({
            name: 'remark-022',
            value: `Spouse: ${
              spouse.name || '???'
            } - Is this spouse still in the household?: Yes - License: ${
              spouse.license_number || 'N/A'
            } - Carrier: ${spouse.carrier} - Policy #: ${
              spouse.policy || 'N/A'
            } - Expiry Date: ${
              spouse.expiry_date || 'N/A'
            } - Unlicensed Reason: ${
              spouse.unlicensed_reason || 'N/A'
            } - Remarks: ${spouse.remarks || 'N/A'}`,
            key: `spouse-${index + 1}`,
          }),
        );
      }
    });
  }, [spouses, dispatch]);

  const onToggleChange = (index: number, status: boolean) => {
    dispatch(
      changeUnlistedSpouse({ name: 'is_in_household', value: status, index }),
    );
  };

  const onSpousesChange = (event: any, index: number) => {
    const { name, value } = event.target;

    dispatch(changeUnlistedSpouse({ name, value, index }));
  };

  const onDateChange = (date: Date, name: string, index: number) => {
    dispatch(
      changeUnlistedSpouse({
        name,
        index,
        value: getDateString(date),
      }),
    );
  };

  if (!validation || !spouses) return null;

  return (
    <Box mb="20px">
      <Divider mb="10px">Unlisted spouses</Divider>

      <Alert status="warning" mb="20px" fontSize="14px">
        <AlertIcon />
        <Box w="100%">
          <AlertDescription>
            <ValidationMessage message={validation.validation_message} />
          </AlertDescription>
        </Box>
      </Alert>

      <Box pl="20px">
        {spouses.map((spouse, index: number) => {
          return (
            <Box mt={index !== 0 ? '20px' : ''} key={index}>
              <Box backgroundColor="#ccc" rounded="5px" p="5px" pl="10px">
                <strong>({index + 1}) Spouse: </strong> {spouse.name || '???'}
              </Box>

              <Flex mt="10px" mb="10px" gap="20px">
                <Box w="30%">
                  <FormInput
                    section={SECTIONS.DRIVERS}
                    label="Name"
                    name="name"
                    value={spouse.name}
                    onChange={(e) => onSpousesChange(e, index)}
                  />
                </Box>
                <Box w="70%" pt="40px">
                  Is this spouse still in the household?
                  <CheckBoxInput
                    section={SECTIONS.DRIVERS}
                    isChecked={spouse.is_in_household}
                    onChange={(e) => onToggleChange(index, true)}
                    ml="20px"
                  >
                    Yes
                  </CheckBoxInput>
                  <CheckBoxInput
                    section={SECTIONS.DRIVERS}
                    isChecked={!spouse.is_in_household}
                    onChange={(e) => onToggleChange(index, false)}
                    ml="20px"
                  >
                    No
                  </CheckBoxInput>
                </Box>
              </Flex>

              {!spouse.is_in_household && (
                <Box>
                  <FormInput
                    section={SECTIONS.DRIVERS}
                    label="Reason not in household"
                    name="no_reason"
                    value={spouse.no_reason}
                    onChange={(e) => onSpousesChange(e, index)}
                  />
                </Box>
              )}

              {spouse.is_in_household && (
                <Box>
                  <Flex gap="20px">
                    {/* <FormInput
                      label="Name"
                      name="name"
                      value={spouse.name}
                      onChange={(e) => onSpousesChange(e, index)}
                    /> */}
                    <FormInput
                      section={SECTIONS.DRIVERS}
                      label="License"
                      name="license_number"
                      value={spouse.license_number}
                      onChange={(e) => onSpousesChange(e, index)}
                    />
                    <FormInput
                      section={SECTIONS.DRIVERS}
                      label="Carrier"
                      name="carrier"
                      value={spouse.carrier}
                      onChange={(e) => onSpousesChange(e, index)}
                    />
                    <FormInput
                      section={SECTIONS.DRIVERS}
                      label="Policy #"
                      name="policy"
                      value={spouse.policy}
                      onChange={(e) => onSpousesChange(e, index)}
                    />
                    <DateInput
                      section={SECTIONS.DRIVERS}
                      label="Expiry Date"
                      date={getDateFromString(spouse.expiry_date)}
                      onChange={(date: Date) =>
                        onDateChange(date, 'expiry_date', index)
                      }
                    />
                  </Flex>

                  <Flex gap="20px" mt="10px">
                    <Box w="40%">
                      <TextFieldInput
                        label="If spouse is unlicensed, give explanation"
                        name="unlicensed_reason"
                        value={spouse.unlicensed_reason}
                        onChange={(e: Event) => onSpousesChange(e, index)}
                      />
                    </Box>

                    <Box w="60%">
                      <TextFieldInput
                        label="Remarks"
                        name="remarks"
                        value={spouse.remarks}
                        onChange={(e: Event) => onSpousesChange(e, index)}
                      />
                    </Box>
                  </Flex>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default UnlistedSpouses;
