export const alertStyles = {
  components: {
    Alert: {
      baseStyle: {
        container: {
          borderRadius: 'md',
        },
      },
    },
  },
};
