const CONFIG = {
  ITEMS_PER_PAGE: 50,
  ORGANISATIONS: {
    local: {
      name: 'Local development',
      api_endpoint: 'http://localhost:8000/',
      auto_detect_host: '',
    },
    development: {
      name: 'Development',
      api_endpoint: 'https://api.dev.big.policypilot.ai/',
      auto_detect_host: '',
    },
    'my-hunter-dev': {
      name: 'My Hunter Dev',
      api_endpoint: 'https://api.dev.my-hunter.policypilot.ai/',
      auto_detect_host: 'myhunter.dev.policypilot.ai',
    },
    'my-hunter': {
      name: 'My Hunter',
      api_endpoint: 'https://api.my-hunter.policypilot.ai/',
      auto_detect_host: 'myhunter.policypilot.ai',
    },
  },
};

export default CONFIG;
