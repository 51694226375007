import getValidaitonId from '../getValidaitonId';

const getValidationMessage = (
  validations: Validation[],
  key: string | string[],
  failed_object_id?: number | string,
): string => {
  const validationId = getValidaitonId(key, validations);
  const validation = validations.find((v) => v.validation_id === validationId);

  if (!validation) return '';

  if (
    failed_object_id &&
    !validation.failed_objects.includes(failed_object_id as any)
  ) {
    return '';
  }

  return validation.validation_outcome !== 'pass'
    ? `(${validation.validation_id}) ${validation.validation_name} : ${validation.validation_message}`
    : '';
};

export default getValidationMessage;
