import { useContext } from 'react';

import { useAppSelector, useAppDispatch } from 'hooks';

import { Text, Box, Button } from '@chakra-ui/react';

import Card from 'components/card/Card';

import getValidationMessage from '../../components/Message/getValidationMessage';
import Message from '../../components/Message';
import Divider from '../../components/Divider';

import { ApplicationFormContext } from 'contexts/ApplicationFormContext';

import { FILE_TYPE, SECTIONS } from 'views/submission/constants';

import {
  changeAccidentsAndClaims,
  addAccidentsAndClaims,
  removeAccidentsAndClaims,
  changeOtherLoss,
  addOtherLoss,
  removeOtherLoss,
} from 'reducers/submissionFormReducer';

import Claim from './components/Claim';

import isSubmissionDisabled from 'views/submission/helpers/isSubmissionDisabled';

import getDateString from 'helpers/getDateString';

import { AuthContext } from 'contexts/AuthContext';

const Claims = () => {
  const { organization } = useContext(AuthContext);
  const { validations } = useContext(ApplicationFormContext) as {
    validations: any;
  };
  const dispatch = useAppDispatch();

  const claims = useAppSelector(
    (state) => state.submissionForm.data.accidents_and_claims,
  );

  const other_losses = useAppSelector(
    (state) => state.submissionForm.data.other_losses || [],
  );

  const submissionStatus = useAppSelector(
    (state) => state.submissionForm.status,
  );

  const isDisabled = isSubmissionDisabled(submissionStatus, organization.role);

  const onClaimChange = (event: any, index: number) => {
    const { name, value, checked, type } = event.target;
    const payload = type === 'checkbox' ? checked : value;

    dispatch(changeAccidentsAndClaims({ name, index, value: payload }));
  };

  const onClaimDateChange = (date: Date, name: string, index: number) => {
    dispatch(
      changeAccidentsAndClaims({ name, index, value: getDateString(date) }),
    );
  };

  const onOtherLossChange = (event: any, index: number) => {
    const { name, value, checked, type } = event.target;
    const payload = type === 'checkbox' ? checked : value;

    dispatch(changeOtherLoss({ name, index, value: payload }));
  };

  const onOtherLossDateChange = (date: Date, name: string, index: number) => {
    dispatch(changeOtherLoss({ name, index, value: getDateString(date) }));
  };

  return (
    <Card mb="20px" id="sectionClaims">
      <Text fontWeight="bold" fontSize="2xl" mb="20px">
        Previous Accidents and Insurance Claims
      </Text>

      <Text fontSize="xs">
        Give details of all accidents or claims arising from the ownership, use
        or operation of any automobile by the applicant or any listed driver
        during the last 6 years. The coverages are: BI - Bodily Injury, PD -
        Property Damage, AB - Accident Benefits, DCPD - Direct , Compensation -
        Property Damage, UA - Uninsured Automobile, Coll- Collision, AP - All
        Perils, Comp - Comprehensive, SP - Specified Perils
      </Text>

      {claims.map((claim, index) => {
        return (
          <Claim
            key={index}
            index={index}
            claim={claim}
            onChange={onClaimChange}
            onDateChange={onClaimDateChange}
            onRemoveClaim={() => dispatch(removeAccidentsAndClaims(index))}
            isDisabled={isDisabled}
          />
        );
      })}

      <Box mt="20px">
        <Button
          variant="brand"
          onClick={() => dispatch(addAccidentsAndClaims())}
          disabled={isDisabled}
        >
          Add new
        </Button>
      </Box>

      {getValidationMessage(validations, '032') && (
        <Message validationId="032" section={SECTIONS.CLAIMS} />
      )}

      {other_losses.length > 0 && (
        <>
          <Divider mt="20px">Other Losses</Divider>

          {other_losses.map((other_loss, index) => {
            return (
              <Claim
                key={index}
                index={index}
                claim={other_loss}
                onChange={onOtherLossChange}
                onDateChange={onOtherLossDateChange}
                onRemoveClaim={() => dispatch(removeOtherLoss(index))}
                isDisabled={isDisabled}
              />
            );
          })}

          <Box mt="20px" mb="10px">
            <Button
              variant="brand"
              onClick={() => dispatch(addOtherLoss())}
              disabled={isDisabled}
            >
              Add new
            </Button>
          </Box>

          <Message
            section={SECTIONS.CLAIMS}
            validationId="other_losses"
            file={FILE_TYPE.TYPE_OTHER_LOSS_LETTER_OF_EXPERIENCE}
            manual={{
              show: true,
              message:
                'Other Losses Claim(s) present, please provide remarks explaining the claim and upload policy documentation or letter of experience if necessary',
            }}
          />
        </>
      )}
    </Card>
  );
};

export default Claims;
