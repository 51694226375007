import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
  subWeeks,
} from 'date-fns';

const getDateRangeLabel = (startDate: Date, endDate: Date) => {
  if (!startDate || !endDate) {
    return '';
  }

  const today = new Date();

  // Check if the range is Today
  if (
    startOfDay(startDate).getTime() === startOfDay(today).getTime() &&
    endOfDay(endDate).getTime() === endOfDay(today).getTime()
  ) {
    return 'Today';
  }

  // Check if the range is Yesterday
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  if (
    startOfDay(startDate).getTime() === startOfDay(yesterday).getTime() &&
    endOfDay(endDate).getTime() === endOfDay(yesterday).getTime()
  ) {
    return 'Yesterday';
  }

  // Check if the range is This Week
  const startOfThisWeek = startOfWeek(today);
  const endOfThisWeek = endOfWeek(today);
  if (startDate >= startOfThisWeek && endDate <= endOfThisWeek) {
    return 'This Week';
  }

  // Check if the range is Last Week
  const startOfLastWeek = startOfWeek(subWeeks(today, 1));
  const endOfLastWeek = endOfWeek(subWeeks(today, 1));
  if (startDate >= startOfLastWeek && endDate <= endOfLastWeek) {
    return 'Last Week';
  }

  // Check if the range is This Month
  const startOfThisMonth = startOfMonth(today);
  const endOfThisMonth = endOfMonth(today);
  if (startDate >= startOfThisMonth && endDate <= endOfThisMonth) {
    return 'This Month';
  }

  // Check if the range is Last Month
  const startOfLastMonth = startOfMonth(subMonths(today, 1));
  const endOfLastMonth = endOfMonth(subMonths(today, 1));
  if (startDate >= startOfLastMonth && endDate <= endOfLastMonth) {
    return 'Last Month';
  }

  // If it doesn't match any of the above, return 'Custom'
  return 'Custom';
};

export default getDateRangeLabel;
